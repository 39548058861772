import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Table } from "antd";
import { closeStatusChangesHistory } from "./redux/slice";
import { usePaggination } from "../../../../../../config/hooks";
import { searchCompanyAnnouncementChange } from "../../.config/redux/actions";
import moment from "moment";
import { ANNOUNCEMENT_STATUS } from "../../../../../../config/helpers/Utils";
import ChangeStatus from "../../components/changeStatus";
import { setIsUserAnnouncement } from "../../components/changeStatus/redux/slice";

export default function (props) {
  const { onClose, onChangeStatus } = props;
  const dispatch = useDispatch();
  const { messages } = useIntl();

  const { isOpen, companyAnnouncementId, isUserAnnouncement, data, loading } = useSelector(
    (state) => state.statusChangesHistoryRedux
  );
  const { updateResult, } = useSelector(
    (state) => state.companyAnnouncementChangesRedux
  );

  useEffect(() => {
    if(updateResult){
      onChangeStatus && onChangeStatus();
    }
  }, [updateResult])

  useEffect(() => {
    if(isOpen){
      dispatch(setIsUserAnnouncement(isUserAnnouncement))
    }
  }, [isOpen])

  const paggination = usePaggination((params) =>
    searchCompanyAnnouncementChange({ ...params, companyAnnouncementId })
  );

  const columns = [
    {
      title: messages["dashboardList_status"],
      key: "status",
      dataIndex: "status",
      render: (value) => {
        let bgcolor = null;
        switch (value) {
          case 1:
            bgcolor = "bg-list-blue";
            break;
          case 2:
            bgcolor = "bg-list-yellow";
            break;
          case 3:
            bgcolor = "bg-list-orange";
            break;
          case 4:
            bgcolor = "bg-list-purple";
            break;
          case 5:
            bgcolor = "bg-list-green";
            break;
          case 6:
            bgcolor = "bg-list-red";
            break;
          default:
            break;
        }

        return (
          <div className={`full-cell-table text-center p-0 ${bgcolor}`}>
            <h3 className="mb-0" style={{ fontSize: 12 }}>{`${
              ANNOUNCEMENT_STATUS[value] || ""
            }`}</h3>
          </div>
        );
      },
    },
    {
      title: messages["updatedBy"],
      key: "user",
      //dataIndex: "createdOn",
      render: (row) =>
        `${row?.user?.firstName || ""} ${row?.user?.lastName || ""}`,
    },
    {
      title: messages["updatedOn"],
      key: "updatedOn",
      width: 200,
      render: (row) =>
        row?.updatedOn && moment(row.updatedOn).format("DD/MM/yyyy HH:mm"),
    },
  ];

  const closeModal = useCallback((refresh) => {
    dispatch(closeStatusChangesHistory());
    onClose && onClose(refresh);
    dispatch(setIsUserAnnouncement(false))
  });
  return (
    <Modal
      open={isOpen}
      width={800}
      onCancel={() => {
        closeModal();
      }}
      footer={null}
      maskClosable={false}
    >
      <div className="p-2 p-md-2">
        <div className="text-center mb-4">
          <h3>{messages["statusChangesHistory_title"]}</h3>
        </div>

        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div style={{ width: 200 }}>
              <ChangeStatus
                refresh={() => paggination.refresh()}
                id={companyAnnouncementId}
                initialValue={data?.items && data?.items[0]?.status}
              />
            </div>
            <div className="d-flex flex-column justify-content-end">
              <button
                className="btn btn-primary m-1"
                onClick={() => {
                  paggination.refresh();
                }}
              >
                {messages["refresh"]}
              </button>
            </div>
          </div>
          <div className="mt-2 table-responsive">
            <Table
              size="small"
              rowKey="id"
              loading={loading}
              columns={columns}
              dataSource={data?.items || []}
              scroll={{ x: 500 }}
              pagination={{
                total: data?.count || 0,
                ...paggination.tablePaggination,
              }}
            />
          </div>
        </div>

        <div className="row pt-5">
          <div className="col-12 text-center">
            <button
              className="btn btn-label-secondary"
              onClick={() => closeModal()}
            >
              {messages["close"]}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
