import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { resetALL } from "../../../../../../../config/redux/actions";
import { clientMutation, clientQuery } from "../../../../../../../config/helpers/GraphQLApi";
import { endpoints } from "../../../../../../../config/constants";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";

//STATE INITIAL
const INIT_STATE = {
  data: null,
  loading: false,
  error: null,
  result: null
};

const route = "modules/management/forms/views/list/redux/slice";


export const searchForms = createAsyncThunk(
  "form/searchForms",
  async (
    { searchText = null, limit = 10, offset = 0,/*  filters, orderBy = [] */ },
    { rejectWithValue, dispatch }
  ) => {
    try {
      let data = await clientQuery(
        `query searchForms ($searchText: String, $limit: Int, $offset: Int){ 
            result: searchForms(searchText: $searchText, limit: $limit, offset: $offset){
                  count
                  items {
                      id
                      title
                      description
                      statusId
                      status {
                        id
                        name
                      }
                      questions {
                        id
                        formId
                        question
                        helpText
                        props
                        questionType
                        typeData
                        answers {
                            id
                            questionId
                            answer
                        }
                      }
                  }
            }
          }`,
        {
          searchText,
          limit,
          offset,
          /* filters,
          orderBy: JSON.stringify(orderBy) */
        },
        endpoints.GRAPHQL_GENERAL
      );

      return data?.result || null;
    } catch (exc) {
      ExceptionManager(exc, route, "searchForms", dispatch);
      return rejectWithValue(exc);
    }
  }
);

export const deleteForm = createAsyncThunk(
  "form/deleteForm",
  async (
    { id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      let data = await clientMutation(
        `mutation deleteForm ($id: Int!){ 
            result: deleteForm(id: $id)
          }`,
        {
          id: Number(id)
        },
        endpoints.GRAPHQL_GENERAL
      );

      return data.result;
    } catch (exc) {
      ExceptionManager(exc, route, "deleteForm", dispatch);
      return rejectWithValue(exc);
    }
  }
);

//Slice
export const formsSlice = createSlice({
  name: "formsList",
  initialState: INIT_STATE,
  reducers: {
    reset(state, action) {
      return action.payload
        ? { ...state, ...action.payload }
        : { ...INIT_STATE };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetALL, () => INIT_STATE);

    // List
    builder.addCase(searchForms.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchForms.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(searchForms.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // DELETE
    builder.addCase(deleteForm.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteForm.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.result = payload;
    });
    builder.addCase(deleteForm.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = formsSlice;

// Extract and export each action creator by name
export const { reset } = actions;

// Export the reducer, either as a default or named export
export default reducer;
