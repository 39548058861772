import { Button } from "antd";
import { useState, useEffect } from "react";
import SortableList from "../../../../../../components/SortableList";
import { useIntl } from "react-intl";
import EditQuestionModal from "../../modals/editQuestion";
import FormItem from "../../../../../../components/FormQuestion";

function QuestionItem({ data, remove, edit }) {
    const { position } = data;
    const intl = useIntl();
    const { messages } = intl;

    const renderData = (values) => {
        const _values = []
        for (const key in values) {
            const element = values[key];
            _values.push(<li key={key}><b>{key}:</b> {element}</li>)
        }
        return _values
    }
    return (
        <div
            className="col-12"
        >
            <div className="text-end">
                <button
                    type="button"
                    className="btn btn-sm btn-icon"
                    onClick={() => {
                        edit(data);
                    }}
                ><i className="bx bx-edit"></i></button>
                <button
                    type="button"
                    className="btn btn-sm btn-icon"
                    onClick={() => {
                        remove(position);
                    }}
                ><i className="bx bx-trash"></i></button>
            </div>
            <FormItem {...data} />
        </div>
    );
}

function ListQuestions(props) {
    const { onChange = () => { }, value, form } = props;
    const [_value, setValue] = useState([]);
    const [showEditQuestion, setShowEditQuestion] = useState(false)
    const [dataToEdit, setDataToEdit] = useState(null)

    useEffect(() => {
        if (value) {
            setValue(value);
        }
    }, [value]);

    const closeEdit = () => {
        setShowEditQuestion(false)
        setDataToEdit(null);
    }

    const openEdit = (values) => {
        setShowEditQuestion(true);
        setDataToEdit(values)
    }

    const onChangeValues = (newVal) => {
        setValue(() => {
            let _newVal = [...newVal];
            _newVal = _newVal.map((value) => ({
                ...value,
            }));
            onChange(_newVal);
            return _newVal;
        });
    };

    const onSaveEdit = (values) => {
        setValue(curr => {
            const newValues = [...curr]
            const index = curr.findIndex(v => v.position == values.position)
            newValues[index] = values;
            onChange(newValues);
            return newValues
        });
        setTimeout(() => {
            form.submit();
        }, 100);

        closeEdit()
    }

    const onAdd = (values) => {
        setValue((curr) => {
            const newVal = {
                position: curr.length + 1,
                question: "",
                helpText: "",
                props: '{}',
                typeData: null,
                ...values
            };
            const _newValues = [
                ...curr,
                newVal
            ];
            openEdit(newVal)
            return _newValues;
        });
    };

    const onDelete = (itemPos) => {
        setValue((curr) => {
            let _newVal = [...curr].filter(
                (value) => value.position !== itemPos
            );
            _newVal = _newVal.map((value, index) => ({
                ...value,
                position: index + 1,
            }));
            onChange(_newVal);
            return _newVal;
        });
    };

    return (
        <>
            <SortableList
                items={_value}
                onChange={onChangeValues}
                keyProp="position"
                renderItem={(item, index) => (
                    <SortableList.Item key={item.position} id={item.position}>
                        <QuestionItem data={item} field={{ name: index }} remove={onDelete} edit={openEdit} />
                        <SortableList.DragHandle />
                    </SortableList.Item>
                )}
            />
            <Button type="dashed" onClick={() => onAdd()} block>
                + Add Question
            </Button>
            <EditQuestionModal show={showEditQuestion} close={closeEdit} onSave={onSaveEdit} data={dataToEdit} />
        </>
    );
}

export default ListQuestions;
