import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useIntl } from "react-intl"

import { Button, Form, Input, Alert, notification } from 'antd';
import { Loading } from '../../../../components/Loading'
import Footer from '../../../layouts/footer'

import { recoveryPassword, reset } from './redux/slice'


const RecoveryPasswordView = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { messages, formatMessage } = useIntl()
    const [form] = Form.useForm()

    const { loading, result } = useSelector(state => state.recoveryPasswordRedux)
    const [errorMessage, setErrorMessage] = useState()

    useEffect(() => {
        if (result) {

            console.log("result", result)
            if (result.error) {
                setErrorMessage(messages['recovery_password_error'])
                form.resetFields()
            }
            else if (result.code == "ok") {

                notification.open({
                    message: messages['recovery_password_recovery_title'],
                    description: messages['recovery_password_recovery_msg'].replace('{email}', result.data.email)
                })

                navigate("/login")
            }

            dispatch(reset())
        }
    }, [result])


    return (
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner py-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="app-brand justify-content-center">
                                <a href="index.html" className="app-brand-link gap-2">
                                    <span className="app-brand-logo demo">
                                        <img src='/assets/images/logo_tet.jpg' />
                                    </span>
                                </a>
                            </div>
                            <h4 className="mb-2">{messages["recovery_password_title"]} 🔒</h4>
                            {/* <p className="mb-4">Enter your email and we'll send you instructions to reset your password</p> */}
                            {
                                errorMessage &&
                                <div className="mb-3 row">
                                    <div className="col-12">
                                        <Alert
                                            message={errorMessage}
                                            type="error"
                                            closable
                                            onClose={() => setErrorMessage()}
                                        />
                                    </div>
                                </div>
                            }
                            <Loading loading={loading}>
                                <Form
                                    form={form}
                                    name="basic"
                                    layout="vertical"
                                    initialValues={{ remember: true }}
                                    onFinish={(values) => {

                                        setErrorMessage()
                                        dispatch(recoveryPassword(values))

                                    }}
                                    // onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    rootClassName='pb-3'
                                >
                                    <div>
                                        <label className="form-label">{messages["login_username"]}</label>
                                        <Form.Item
                                            name="email"
                                            rules={[{ required: true, message: messages["required_field"] }]}
                                        >
                                            <Input placeholder={messages["recovery_password_email"]} className="form-control" />
                                        </Form.Item>
                                    </div>

                                </Form>

                                <div className="pb-3">
                                    <Button className="btn btn-primary w-100" onClick={() => { form.submit() }}>{messages["recovery_password_send"]} </Button>
                                </div>
                                <div className="text-center">
                                    <a className="d-flex align-items-center justify-content-center" onClick={() => { navigate("/login") }}>
                                        {messages["recovery_password_back"]}
                                    </a>
                                </div>
                            </Loading>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default RecoveryPasswordView