import { InputFile } from "../InputFile"


const UploadInput = () => {
  return (
    <div>
      <InputFile />
    </div>
  )
}

export default UploadInput