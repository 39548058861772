import { Fragment, useMemo, useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { BsFiletypeDoc } from 'react-icons/bs';
import { BiMailSend } from 'react-icons/bi';

import { Menu } from 'antd';

import { useSetThemeValues } from '../../../config/hooks'

import {
    changeMenu
} from '../../../config/redux/actions'
import { P_AFFILIATED_PARTNERS, P_AGENTS, P_COMPANIES, P_MARKETING_SERVICES, 
    P_PERMISSIONS, P_ROLES, P_USERS, P_USERS_MANAGEMENT, P_EMAIL_CAMPAIGN, P_EMAIL_SENT, 
    P_EMAIL_ARCHIVE, P_CONFIGURATIONS, P_LEAD_CENTER, P_REMINDER_NOTE_HISTORY, P_LEAD_MANAGER,
    P_DOCUMENT_CONTRACTS, P_FILES, P_ANNOUNCEMENT_CONTRACT_MANAGE, P_GL_AGENTE, P_AREA_MANAGER, P_HTTP_LOGS } from "../../../config/helpers/permissionsHash";


const sidebar = ({ className, collapMenu = false, show = true }) => {

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const { messages } = useIntl()
    const { collapseMenu, isHover, expadnded, onCollapseMenu, onHoverMenu } = useSetThemeValues();

    const { user } = useSelector(state => state.authRedux)

    const [openKeys, setOpenKeys] = useState([]);
    useEffect(() => {
        if(collapMenu){
            onCollapseMenu()
        }
        setOpenKeys(paths);
    }, [collapMenu])

    let pahtsArray = location.pathname.replace('/admin/', '').split('/');
    let initialPaht = "/admin"
    let paths = pahtsArray.map(x => {
        initialPaht = initialPaht + "/" + x
        return initialPaht
    })

    const handleOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => !openKeys.includes(key));
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    };

    const menuItems = useMemo(() => [
        (user?.isAdmin ? {
            label: messages['main_menu'] || 'La tua Home',
            key: '/admin/main',
            icon: <i className="menu-icon tf-icons bx bx-home-alt"></i>
        }: null),
        {
            label: messages['sidebar_usersManagement'],
            key: '/admin/management/roles',
            icon: <i className="menu-icon tf-icons bx bx-user"></i>,
            permission: P_USERS_MANAGEMENT,
            permissionkeys: ['C','R', 'U', 'D'],
            children: [
                {
                    label: messages["sidebar_users"],
                    key: '/admin/management/users/list',
                    permission: P_USERS,
                    permissionkeys: ['C','R', 'U', 'D'],
                },
                {
                    label: messages["sidebar_roles"],
                    key: '/admin/management/roles/list',
                    permission: P_ROLES,
                    permissionkeys: ['C','R', 'U', 'D'],
                },
                {
                    label: messages["sidebar_permissions"],
                    key: '/admin/management/permissions/list',
                    permission: P_PERMISSIONS,
                    permissionkeys: ['C','R', 'U'],
                },
            ]
        },
        {
            label: messages['sidebar_Companies'],
            key: '/admin/management/companies',
            icon: <i className="menu-icon tf-icons bx bx-briefcase-alt"></i>,
            permission: P_COMPANIES,
            permissionkeys:['C','R', 'U', 'D'],
            children: [
                {
                    type: 'list',
                    label: messages['sidebar_lista1'],
                    key: '/admin/management/companies/list',
                    permission: P_MARKETING_SERVICES,
                    permissionkeys: ['C','R', 'U', 'D'],
                },
                {
                    type: 'list',
                    label: messages['sidebar_list2'],
                    key: '/admin/management/companies/list2',
                    permission: P_AFFILIATED_PARTNERS,
                    permissionkeys: ['C','R', 'U', 'D'],
                },
                {
                    type: 'list',
                    label: messages['sidebar_Agenti'],
                    key: '/admin/management/companies/list3',
                    permission: P_AGENTS,
                    permissionkeys: ['C','R', 'U', 'D'],
                },
                {
                    type: 'list',
                    label: messages['sidebar_areaManager'],
                    key: '/admin/management/companies/list4',
                    permission: P_AREA_MANAGER,
                    permissionkeys: ['C','R', 'U', 'D'],
                }
            ]
        },
        {
            label: messages['sidebar_emailCampaign'],
            key: '/admin/management/campaigns',
            icon: <BiMailSend size={20}/>,
            permission: P_EMAIL_CAMPAIGN,
            permissionkeys: ['C','R', 'U', 'D'],
            children: [
                {
                    permission: P_EMAIL_SENT,
                    permissionkeys: ['C','R', 'U', 'D'],
                    type: 'list',
                    label: messages['sidebar_emailSentList'],
                    key: '/admin/management/campaigns/list'
                }
            ]
        },
        {
            label: messages['sidebar_parametri'],
            key: '/admin/management/parameters',
            icon: <i className="menu-icon tf-icons bx bx-tachometer"></i>,
            permission: P_EMAIL_ARCHIVE,
            permissionkeys: ["R"],
            children: [
                {
                    type: 'list',
                    permission: P_CONFIGURATIONS,
                    permissionkeys: ['C','R', 'U', 'D'],
                    label: messages['sidebar_listParametri'],
                    key: '/admin/management/parameters/list'
                },
                {
                    type: 'list',
                    permission: P_CONFIGURATIONS,
                    permissionkeys: ['C','R', 'U', 'D'],
                    label: messages['sidebar_workFlowManagement'],
                    key: '/admin/management/parameters/workflow/list'
                },
                {
                    type: 'list',
                    permission: P_HTTP_LOGS,
                    permissionkeys: ['C','R', 'U', 'D'],
                    label: messages['httpLogs_menu'],
                    key: '/admin/management/http-logs/list'
                },
                {
                    type: 'list',
                    permission: P_HTTP_LOGS,
                    permissionkeys: ['C','R', 'U', 'D'],
                    label: messages["forms_menu"],
                    key: '/admin/management/forms/list'
                }
            ]
        },
        {
            label: user?.roles?.businessCode == 2 ? messages['agenty_menu'] : messages['client_menu'],
            key: '/admin/client/dashboard',
            icon: <i className="menu-icon tf-icons bx bx-home-alt"></i>,
            permission: P_LEAD_CENTER,
            permissionkeys: ['C','R', 'U', 'D'],
            children:  [
                {
                    type: 'list',
                    label: messages['dashboard_menu'],
                    key: '/admin/client/dashboard/list',
                    permission: P_LEAD_MANAGER,
                    permissionkeys: ['C','R', 'U', 'D'],
                },
                {
                    type: 'list',
                    label: messages['dashboard_menu'] + ' AGENTE',
                    key: '/admin/client/dashboard/agentlist',
                    permission: P_GL_AGENTE,
                    permissionkeys: ['C','R', 'U', 'D'],
                },
                {   
                    type: 'list',
                    label: messages['sidebar_emailProgramming'],
                    key: '/admin/client/email-programming/list',
                    permission: P_REMINDER_NOTE_HISTORY,
                    permissionkeys: ['C','R', 'U', 'D'],
                },
                {
                    type: 'list',
                    label: messages['announcementsContractManage_menu'],
                    key: '/admin/client/announcements-contracts/list',
                    permission: P_ANNOUNCEMENT_CONTRACT_MANAGE,
                    permissionkeys: ['C','R', 'U', 'D'],
                },
            ]
        },
        {
            label: messages['sidebar_documentsAndContracts'],
            key: '/admin/client/documents-contracts/',
            icon: <BsFiletypeDoc size={20}/>,
            permission: P_DOCUMENT_CONTRACTS,
            permissionkeys: ['C','R', 'U', 'D'],
            children:  [
                {
                    permission: P_FILES,
                    permissionkeys: ['C','R', 'U', 'D'],
                    type: 'list',
                    label: messages['sidebar_documentsUpdated'],
                    key: '/admin/client/documents-contracts/list'
                },
            ]

        }
    ],[]);

    const createMenuItems = useCallback((items) => {
        if(user.isAdmin){
            return items;
        }
        const  permissions = user?.permissions;
        return items.filter(item => {
            if(!item?.permission){
                return true;
            }

            const permissionFound = permissions.find(p => p.hash === item.permission);
        
            if (permissionFound) {
                if (item.permissionkeys) {
                    const keys = item.permissionkeys;
                    const hasValidPermission = keys.some(key => Object.hasOwnProperty.call(permissionFound, key) && permissionFound[key]);
        
                    if (hasValidPermission) {
                        if (item.children) {
                            item.children = createMenuItems(item.children);
                            return item.children.length > 0;
                        }
                        return true;
                    }
                }
            }
            return false;
        });
    }, [user])

    if(!show){
        return <></>
    }

    return (
        <Fragment>
            <>
            {/* <aside
                id="layout-menu"
                className="layout-menu menu-vertical menu bg-menu-theme"
                onMouseOver={() => mouseOver()}
                onMouseLeave={() => {
                    if (collapseMenu) {
                        setCollapseInline(true)
                        document.body.classList.remove(['layout-menu-hover']);
                    }
                }}
            >
                <div className="app-brand demo">
                    <a href="index.html" className="app-brand-link">
                        <span className="app-brand-logo">
                           
                            <img src="/assets/images/logo-mini.png" height={25} />
                        </span>
                        <span className="app-brand-text demo menu-text fw-bolder ms-2">EDILNET</span>
                    </a>

                    <a className="layout-menu-toggle menu-link text-large ms-auto cursor-pointer" onClick={() => { dispatch(changeMenu({ collapseMenu: expadnded ? collapseMenu : !collapseMenu, expadnded: expadnded ? false : expadnded })) }}>
                        <Icons name="FaAngleLeft" family="fa" className="bx bx-chevron-left bx-sm align-middle m-1" color="#ffffff" size={18} />
                    </a>
                </div>

                <div className="menu-inner-shadow"></div>

              
                <Menu
                    onClick={(e) => navigate(e.key)}
                    // style={{
                    //     width: 256,
                    // }}
                    selectedKeys={paths}
                    defaultOpenKeys={paths}
                    mode="inline"
                    items={items}
                    inlineCollapsed={collapseInline}
                />
            </aside>
            <div className="layout-overlay layout-menu-toggle" onClick={() => {
                if (expadnded) {
                    dispatch(changeMenu({ expadnded: false }))
                }
            }}></div> */}
            </>
            <aside
                id="layout-menu"
                className={className || "layout-menu menu-vertical menu bg-menu-theme"}
                onMouseOver={() => onHoverMenu(true)}
                onMouseLeave={() => onHoverMenu(false)}
            >
                <div className="app-brand demo">
                    <a className="app-brand-link" onClick={() => { navigate("/") }} >
                        <span className="app-brand-logo demo">
                            <img src="/assets/images/logo-mini.png" height={25} />
                        </span>
                        {/* <span className="app-brand-text demo menu-text fw-bolder ms-2">Edilnet</span> */}
                    </a>

                    <a
                        //href=""
                        className={classNames("layout-menu-toggle menu-link text-large ms-auto", isHover && 'd-block')}
                        onClick={() => onCollapseMenu()}
                    >
                        <i className="bx bx-chevron-left bx-sm align-middle"></i>
                    </a>
                </div>

                <div className="menu-inner-shadow"></div>

                {/* <ul className="menu-inner py-1">

                    <li className="menu-item active">
                        <a href="index.html" className="menu-link">
                            <i className="menu-icon tf-icons bx bx-home-circle"></i>
                            <div data-i18n="Page 1">Page 1</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="page-2.html" className="menu-link">
                            <i className="menu-icon tf-icons bx bx-detail"></i>
                            <div data-i18n="Page 2">Page 2</div>
                        </a>
                    </li>
                </ul>*/}
                <Menu
                    //rootClassName="menu-inner py-1"
                    onClick={(e) => navigate(e.key)}
                    // style={{
                    //     width: 256,
                    // }}
                    openKeys={openKeys}
                    onOpenChange={handleOpenChange}
                    //selectedKeys={openKeys}
                    
                    mode="inline"
                    items={[...createMenuItems(menuItems)]}
                    inlineCollapsed={collapseMenu && !isHover && !expadnded}
                />
            </aside>

            <div className="layout-overlay layout-menu-toggle" onClick={() => expadnded && dispatch(changeMenu({ expadnded: false }))}></div>
        </Fragment>
    )
}

export default sidebar;