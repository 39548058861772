import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux'
import { Loading, RichTextEditor } from "components";
import { Form, Input, Modal, Select, notification } from 'antd'
import { globalParametrizableTextEditClose, globalParametrizableTextEditSave, reset } from './redux/slice';
import { getCatalog } from '../../../../../.config/redux/actions';
import uris from '../editParametrizableText/.config/uris/index'



export const EditParametrizableText = ({ onClose }) => {
    const dispatch = useDispatch();
    const { messages } = useIntl();
    const [form] = Form.useForm();
    const [id, setId] = useState();
    const { loading, isOpen, data, result, error } = useSelector((state) => state.globalParametrizableTextEditRedux);
    const { data: listUris } = useSelector((state) => state.parametrizableTextsListRedux);
    const { roles } = useSelector((state) => state.catalogRedux);

    console.log(listUris, "listUris");
    console.log(data, "data");

    //Verificacion de carga
    useEffect(() => {
        if (data && !id) {
            //se carga la info al form solo una vez
            setId(data.id);
            //se especifica la carga de los valores al form
            // console.log("si esta llegando la data", data, data.id)
            form.setFieldsValue({
                ...data,
            });
        }

        if (result) {
            dispatch(reset({ result: null }));
            notification.success({ message: messages["saveSuccessfully"] }); //, description: messages["saveSuccessfully"] })
            closeModal(true);
        }

        if (error) {
            dispatch(reset({ error: null }));
            notification.error({
                message: messages["saveError"],
                description: messages[error] || error,
            });
        }
    }, [data, result, error]);

    useEffect(() => {
        dispatch(getCatalog({ keys: ["roles"] }));
    }, []);

    //se valida y se limpia todo lo que pueda causar errores
    const closeModal = useCallback((refresh) => {
        setId();
        form.resetFields();
        dispatch(globalParametrizableTextEditClose());
        onClose && onClose(refresh);
    });

    const validateUri = (_, value) => {
        const uriSelected = listUris?.items.find((item) => item.uri === value);
        if (uriSelected && data.id == null) {  
            return Promise.reject(messages["parametizableText_validatorUris"]);
        } 
        return Promise.resolve();
    };

    return (
        <Modal
            open={isOpen}
            width={1500}
            onCancel={() => closeModal()}
            footer={null}
            maskClosable={false}
        >
            <div className="p-3 p-md-5">
                <div className="text-center mb-4">
                    <h3>
                        {id
                            ? messages["parametrizableText_Edit"]
                            : messages["parametrizableText_Create"]}
                    </h3>
                </div>


                <Loading
                    loading={loading}
                >
                    <div
                        className='card-body'
                    >
                        <Form
                            form={form}
                            layout='vertical'
                            onFinish={(values) => {
                                console.log(values);
                                dispatch(
                                    globalParametrizableTextEditSave({
                                        id: id,
                                        ...values,
                                    })
                                );
                            }}
                        >
                            <div className='row g-3'>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12 m-2'>
                                            <Form.Item
                                                name="rolesId"
                                                label={
                                                    <label className="form-label">
                                                        {messages[
                                                            "users_editUser_roles"
                                                        ]}
                                                    </label>
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: messages["required_field"],
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    multiple
                                                    mode="tags"
                                                    style={{ width: "100%" }}
                                                    options={
                                                        roles?.length
                                                            ? roles.map((v) => ({
                                                                label: v.name,
                                                                value: v.id,
                                                            }))
                                                            : []
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className='col-12 m-2'>
                                            <Form.Item
                                                name="uri"
                                                label={
                                                    <label className="form-label">
                                                        {messages["parametrizableText_URI"]}
                                                    </label>
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: messages["required_field"],
                                                    },
                                                    {
                                                        validator: validateUri
                                                    }
                                                ]}
                                            >
                                                <Select

                                                    style={{ width: "100%" }}
                                                    options={
                                                        uris?.length
                                                            ? uris.map((v) => ({
                                                                label: v.name,
                                                                value: v.uri,
                                                            }))
                                                            : []
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className='col-12 m-2'>
                                            <Form.Item
                                                name="description"
                                                label={
                                                    <label className="form-label">
                                                        {messages["parametrizableText_Description"]}
                                                    </label>
                                                }
                                            /* rules={[
                                              {
                                                required: true,
                                                message: messages["required_field"],
                                              },
                                            ]} */
                                            >
                                                <Input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" />
                                            </Form.Item>
                                        </div>
                                        <div className='col-12 m-2'>
                                            <Form.Item
                                                name="content"
                                                label={
                                                    <label className="form-label">
                                                        {messages["parametrizableText_Content"]}
                                                    </label>
                                                }
                                                rules={[
                                                    { required: true, message: messages["required_field"] },
                                                ]}
                                            >
                                                <RichTextEditor height="calc(100vh - 280px)" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Loading>
                <div className="row pt-5">
                    <div className="col-12 text-center">
                        <button
                            type="button"
                            className="btn btn-primary me-sm-3 me-1"
                            onClick={() => form.submit()}
                        >
                            {messages["save"]}
                        </button>
                        <button
                            type="button"
                            className="btn btn-label-secondary"
                            onClick={() => closeModal()}
                        >
                            {messages["cancel"]}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
