import { useEffect } from "react";
import { BsSignStop } from "react-icons/bs";
import { useIntl, } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import {createHttpErrorLog} from '../../../../modules/admin/management/httpLogs/views/list/redux/slice';


const UnAuthorizedView = () => {
    const dispatch = useDispatch()
    const { messages } = useIntl();
    const navigate = useNavigate()

    const { historyRoutes } = useSelector(state => state.authRedux);

    useEffect(() => {
        console.log('log')
        const currentHost = window.location.origin
        dispatch(createHttpErrorLog({originLink: `${currentHost}${historyRoutes[1]}`, destinationLink: `${currentHost}${historyRoutes[0]}`, code: 401}))
    }, [])
    
    return (
        <div className="main-wrapper  d-flex justify-content-center align-items-center vh-100">
            <div className="error-box">
                <div className="error-body text-center mt-">
                    <h1 className="error-title text-danger">401 <BsSignStop size={50} /></h1>
                    <h3 className="text-uppercase error-subtitle">{messages['unAuthorized'].toString().toUpperCase()} !</h3>
                    <p className="text-muted mt-4 mb-4">{('Non siete autorizzati ad accedere a queste informazioni, se ritenete che si tratti di un errore, contattate  l`amministratore.').toLocaleUpperCase()}</p>
                    <a onClick={()=> navigate("/")} className="btn btn-danger btn-rounded waves-effect waves-light mb-5">TORNA A CASA</a>
                </div>
            </div>
        </div>
    )
}

export default UnAuthorizedView;