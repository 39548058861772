import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const usePaggination = (action, _pageSize = 10) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_pageSize);
  const [searchText, setSearchText] = useState("");
  const [params, setParams] = useState({});
  const [lastPage, setLastPage] = useState(1)
  const { user } = useSelector((state) => state.authRedux);
  const dispatch = useDispatch();

  const refresh = useCallback((_page, _searchText, _parms, _pageSize) => {
    console.log("1", _page, _searchText, _parms);

    let newPage = _page || page;
    setPage(newPage);
    setLastPage(page)
    let newSearchText = _searchText != undefined ? _searchText : searchText;
    setSearchText(newSearchText);

    let newParams = { ...params, ..._parms };
    setParams(newParams);

    console.log("refresh", newPage, newSearchText, newParams);

    const ps = _pageSize || pageSize;

    dispatch(
      action({
        limit: ps,
        offset: ((newPage || page) - 1) * ps,
        searchText: newSearchText,
        ...newParams,
        roles: user?.roles?.map((rol) => rol.id),
      })
    );
  });

  return {
    page,
    pageSize,
    params,
    refresh: refresh,
    tablePaggination: {
      pageSize: pageSize,
      onChange: (page, pageSize) => {
        setPageSize(pageSize);
        refresh(page, null, null, pageSize);
      },
    },
    searchText,
    onSearchChange: (e) => {
      setSearchText(e.target.value);

      if (window.typingTime) {
        clearTimeout(window.typingTime);
      }

      window.typingTime = setTimeout(() => {
        if(e.target.value){
          refresh(1, e.target.value);
        }else {
          refresh(lastPage, e.target.value);
        }
        
      }, 1000);
    },
  };
};
