const debug = localStorage.getItem("debug") == "true" || false;

let url = process.env.URL_API || "https://edilnetqa.ingeniosoft.net";
if(debug)
    url = "http://localhost:10003"

console.log("url", url, debug, process.env.URL_API)

export const endpoints = {
    GRAPHQL_AUTH: `${url}/api/auth`,
    GRAPHQL_GENERAL: `${url}/api/data`,
    FILE_PUT:`${url}/api/upload`
}

export const captcha = {
    REACT_APP_SITE_KEY: process.env.REACT_APP_SITE_KEY
}