let locales = {
    parameters_name: 'Parametros del sistema',
    parameters_senders: 'Remitentes',
    parameters_templates: 'Plantillas',
    parameters_parametizableText: 'Textos parametrizables',
    parameters_config_agent_notifications: 'Notificaciones de agente',
    parameter_deleteCampaignFrom_message: 'Eliminar remitente',
    parameter_deleteCampaignTemplate_message: 'Eliminar template'
};

export default locales;