import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery, clientMutation } from '../../../../../../../config/helpers/GraphQLApi';
import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'
import { uploadFile } from "../../../../../../common/files/redux/slice";
import { v4 } from "uuid";
//STATE INITIAL
const INIT_STATE = {
    loading: false,
    show: false,
    data: null,
    result: null,
    error: null
};

const route = 'routes/admin/configurations/users/modals/edit/redux/slice'

//Extend async Action
export const openEditUser = createAsyncThunk('users/edit/open', async ({ id }, { rejectWithValue }) => {
    try {
        if (!id) return null;

        let data = await clientQuery(
            `
            query getUserById($id: Int!) {
                result: getUserById(id: $id) {
                    id
                    username
                    email
                    firstName
                    lastName
                    fullName
                    imageUrl
                    fileUuidKey
                    companyId
                    isAdmin
                    status
                    isDeleted
                    state
                    paridaIVA
                    city
                    address
                    phone1
                    phone2
                    workState
                    workActivity
                    freeStartDate
                    freeEndDate
                    company {
                        name
                    }
                    hashp
                    roles {
                        id
                        name
                    }
                    assignedUsers {
                        id
                    }
                    supervisors {
                        id
                    }
                    companiesAssigned {
                        id
                    }
                }
            }
            `,
            {
                id: Number(id)
            },
            endpoints.GRAPHQL_GENERAL,
        );

        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'openEditUser');
        return rejectWithValue(exc)
    }
})

export const saveEditUser = createAsyncThunk('users/edit/save', async ({ id, firstName, lastName, email, roles, image, username, assignedUsersIds, freeEndDate, freeStartDate, state, workActivity, workState, phone1, phone2, city, paridaIVA, address, assignedSupervisorsId, assignedCompaniesId, fileUuidKey, imageUrl  }, { rejectWithValue }) => {

    try {
        let data;
        let signedData = null;
        let imageId =  fileUuidKey || v4()
        if (image) {
            signedData = await uploadFile({ file: image, hash: imageId, entityName: "admin_users", entityId: imageId })
        }

        if (id) {
            data = await clientMutation(
                `mutation updateUser($id: Int!, $firstName: String, $lastName: String, $imageUrl: String, $roles: [Int], $assignedUsersIds: [Int], $workActivity: String, $workState: [Int], $freeEndDate: Datetime, $freeStartDate: Datetime, $phone1: String, $phone2: String, $address: String, $city: String, $state: Int, $paridaIVA: String, $assignedSupervisorsId: [Int], $assignedCompaniesId: [Int]) {
                    result: updateUser(id: $id, firstName: $firstName, lastName: $lastName, imageUrl: $imageUrl, roles: $roles, assignedUsersIds: $assignedUsersIds, workActivity: $workActivity, workState: $workState, freeEndDate: $freeEndDate, freeStartDate: $freeStartDate, phone1: $phone1, phone2: $phone2, address: $address, city: $city, state: $state, paridaIVA: $paridaIVA, assignedSupervisorsId: $assignedSupervisorsId, assignedCompaniesId: $assignedCompaniesId) {
                        id
                    }
                }`,
                {
                    username,
                    firstName,
                    lastName,
                    imageUrl,
                    roles,
                    state,
                    paridaIVA,
                    city,
                    address,
                    phone1,
                    phone2,
                    workState,
                    workActivity,
                    freeStartDate,
                    freeEndDate,
                    id: Number(id),
                    assignedUsersIds,
                    assignedSupervisorsId,
                    assignedCompaniesId
                },
                endpoints.GRAPHQL_GENERAL,
            );
        }
        else {
            data = await clientMutation(
                ` mutation createUser($username: String, $email: String, $firstName: String, $lastName: String, $imageUrl: String, $fileUuidKey: String, $roles: [Int],$paridaIVA: String, $state: Int, $city: String, $address: String, $phone1: String, $phone2: String, $freeStartDate: Datetime, $freeEndDate: Datetime, $workState: [Int], $workActivity: String, $assignedUsersIds: [Int]) {
                    result: createUser(username: $username, email: $email, firstName: $firstName, lastName: $lastName, imageUrl: $imageUrl, fileUuidKey: $fileUuidKey, paridaIVA: $paridaIVA, state: $state, city: $city, address: $address, phone1: $phone1, phone2: $phone2, freeStartDate: $freeStartDate, freeEndDate: $freeEndDate, workState: $workState, workActivity: $workActivity, roles: $roles, assignedUsersIds: $assignedUsersIds) {
                        id
                    }
                } `,
                {
                    username,
                    email,
                    firstName,
                    lastName,
                    imageUrl: signedData.url,
                    fileUuidKey: imageId,
                    roles,
                    //image,
                    state,
                    paridaIVA,
                    city,
                    address,
                    phone1,
                    phone2,
                    workState,
                    workActivity,
                    freeStartDate,
                    freeEndDate,
                    assignedUsersIds
                },
                endpoints.GRAPHQL_GENERAL,
            );
        }
        return data.result


    } catch (exc) {
        ExceptionManager(exc, route, 'saveEditUser');
        return rejectWithValue(exc)
    }
})


//Slice
const usersEditRedux = createSlice({
    name: 'users/edit',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        hide(state) {
            state.show = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(openEditUser.pending, (state) => {
            state.loading = true
            state.show = true
        })
        builder.addCase(openEditUser.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(openEditUser.rejected, (state, { payload }) => {
            state.loading = false
            state.error = payload?.error;
        })

        builder.addCase(saveEditUser.pending, (state) => {
            state.loading = true
        })
        builder.addCase(saveEditUser.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(saveEditUser.rejected, (state, { payload }) => {
            state.loading = false
            state.error = payload?.error;
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = usersEditRedux

// Extract and export each action creator by name
export const { reset, hide } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer