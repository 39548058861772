import { Routes, Route, Navigate } from 'react-router-dom';

import CompanyAnnouncementsListView from './views/list'
import CompanyAnnouncementDetailView from './views/detail';
import { useSecurity } from '../../../../config/hooks';
import { P_LEAD_MANAGER, P_GESTIONE_LEAD_DETAIL, P_GL_AGENTE } from '../../../../config/helpers/permissionsHash';
import UserAnnouncementsListView from './views/listAgent';
import UserAnnouncementDetailView from './views/detailAgent';

const DashboardRouter = () => {
    const LM = useSecurity(P_LEAD_MANAGER);
    const LMA = useSecurity(P_GL_AGENTE);
    const DGL = useSecurity(P_GESTIONE_LEAD_DETAIL)
    return (
        <Routes>
            <Route path='/' element={<Navigate to={`/admin/client/dashboard/list`} />}></Route>
            {LM && <>
                <Route path='/list' element={<CompanyAnnouncementsListView />} />
            </>}
            {DGL && <>
                    <Route path='/detail/:id/:companyId' element={<CompanyAnnouncementDetailView />} />
                    <Route path='/detail/:id' element={<CompanyAnnouncementDetailView />} />
            </>}
            {LMA && <>
                <Route path='/agentlist' element={<UserAnnouncementsListView />} />
                <Route path='/detail/user/:id/:userId' element={<UserAnnouncementDetailView />} />
            </>}
            <Route path="*" element={<Navigate to="/error" />} />
        </Routes>
    )
}

export default DashboardRouter;