import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'
import { uploadFile } from "../../../../../../common/files/redux/slice";

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    result: null,
    userAnnouncement: null,
    errorResult: null,
    error: null
};

const route = 'modules/client/dashboard/views/list/slice'

//Extend async Action
export const companyAnnouncementDetailGetById = createAsyncThunk('dashboard/companyAnnouncementDetailGetById', async (
    { id, companyId },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientQuery(
            `query getCompanyAnnouncementById($id: Int!, $companyId: Int){
                result: getCompanyAnnouncementById(id: $id, companyId: $companyId) {
                    id
                    status
                    companyId
                    approvedDocument
                    lastStatus {
                        status
                        updatedOn
                        user {
                            firstName
                            lastName
                        }
                    }
                    announcement {
                        id
                        product
                        name
                        title
                        max_price
                        description
                        date
                        request_type
                        looking_for
                        looking_for2
                        inspection
                        codicemd5
                        id_comuna
                        phone
                        email
                        contact_time
                        surname
                        definition
                        comuna_name
                        province_name
                    }
                    document {
                        id
                        uuidKey
                        url
                        name
                        type
                    }
                    updatedFileByUser {
                        fullName
                        company {
                            name
                        }
                    }
                    documentHistory {
                        id
                        document {
                            id
                            url
                            name
                            type
                        }
                        updatedFileByUser {
                            fullName
                            company {
                                name
                            }
                        }
                        createdByUser {
                            fullName
                        }
                        isDeleted
                        date
                    }
                }
            }`,
            {
                id: parseInt(id, 10),
                companyId: parseInt(companyId, 10),
            },
            endpoints.GRAPHQL_GENERAL
        );
        if(data?.result)
            return data.result
        else
            throw "Error: no tienes acceso"
    } catch (exc) {
        ExceptionManager(exc, route, 'companyAnnouncementDetailGetById', dispatch);
        return rejectWithValue(exc)
    }
})

export const getUserIdFromCompanyId = createAsyncThunk('dashboard/getUserIdFromCompanyId', async (
    { companyId },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientQuery(
            `query getUserIdFromCompanyId($companyId: Int){
                result: getUserIdFromCompanyId(companyId: $companyId) 
            }`,
            {
                companyId: parseInt(companyId, 10)
            },
            endpoints.GRAPHQL_GENERAL
        );

        return data.result
    } catch (exc) {
        ExceptionManager(exc, route, 'getUserIdFromCompanyId', dispatch);
        return rejectWithValue(exc)
    }
})

export const updateViewCompanyAnnouncement = createAsyncThunk('dashboard/updateViewCompanyAnnouncement', async (
    { id, viewType },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientMutation(
            `mutation updateViewsCompanyAnnouncement($id: Int, $viewType: enumViewType){
                result: updateViewsCompanyAnnouncement(id: $id, viewType: $viewType) 
            }`,
            {
                id: parseInt(id, 10),
                viewType
            },
            endpoints.GRAPHQL_GENERAL
        );

        if(data?.result)
            return data.result
        else
            throw "Error: no tienes acceso"
    } catch (exc) {
        ExceptionManager(exc, route, 'companyAnnouncementDetailGetById', dispatch);
        return rejectWithValue(exc)
    }
})

export const uploadContract = createAsyncThunk('dashboard/uploadContract', async (
    { id, file },
    { rejectWithValue, dispatch }
) => {
    try {

        let signedData = null;
        if(file){
            //todo
            signedData = await uploadFile({ file, entityName: "announcement_contract", entityId: id })
        }
        let data = await clientMutation(
            `mutation uploadAnnouncementContract($id: Int!, $file: InputFile!){
                result: uploadAnnouncementContract(id: $id, file: $file) 
            }`,
            {
                id: parseInt(id, 10),
                file: {
                    id: signedData.uuidKey,
                    uuidKey: signedData.uuidKey,
                    url: signedData.url,
                    name: signedData.name,
                    type: signedData.extension
                }
            },
            endpoints.GRAPHQL_GENERAL
        );

        if(data?.result)
            return data.result
    } catch (exc) {
        ExceptionManager(exc, route, 'uploadContract', dispatch);
        return rejectWithValue(exc)
    }
})

export const deleteContract = createAsyncThunk('dashboard/deleteContract', async (
    { id },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientMutation(
            `mutation deleteAnnouncementContract($id: Int!){
                result: deleteAnnouncementContract(id: $id) 
            }`,
            {
                id: parseInt(id, 10),
            },
            endpoints.GRAPHQL_GENERAL
        );

        if(data?.result)
            return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'deleteContract', dispatch);
        return rejectWithValue(exc)
    }
})

export const approveContractFile = createAsyncThunk('dashboard/approveContractFile', async (
    { id },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientMutation(
            `mutation approveAnnouncementContract($id: Int!){
                result: approveAnnouncementContract(id: $id) 
            }`,
            {
                id: parseInt(id, 10),
            },
            endpoints.GRAPHQL_GENERAL
        );

        if(data?.result)
            return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'approveContractFile', dispatch);
        return rejectWithValue(exc)
    }
})
//Slice
const companyAnnouncementDetailRedux = createSlice({
    name: 'dashboard/companyAnnouncementDetailRedux',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(companyAnnouncementDetailGetById.pending, (state) => {
            state.loading = true
        })
        builder.addCase(companyAnnouncementDetailGetById.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(companyAnnouncementDetailGetById.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload.error
        })

        builder.addCase(updateViewCompanyAnnouncement.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateViewCompanyAnnouncement.fulfilled, (state) => {
            state.loading = false
            //state.data = payload
        })
        builder.addCase(updateViewCompanyAnnouncement.rejected, (state, action) => {
            state.loading = false
            state.error =  action.payload.error
        })

        builder.addCase(uploadContract.pending, (state) => {
            state.loading = true
        })
        builder.addCase(uploadContract.fulfilled, (state, {payload}) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(uploadContract.rejected, (state, action) => {
            state.loading = false
            state.errorResult =  action.payload.error
        })

        builder.addCase(deleteContract.pending, (state) => {
            state.loading = true
        })
        builder.addCase(deleteContract.fulfilled, (state, {payload}) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(deleteContract.rejected, (state, action) => {
            state.loading = false
            state.errorResult =  action.payload.error
        })

        builder.addCase(approveContractFile.pending, (state) => {
            state.loading = true
        })
        builder.addCase(approveContractFile.fulfilled, (state, {payload}) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(approveContractFile.rejected, (state, action) => {
            state.loading = false
            state.errorResult =  action.payload.error
        })

        builder.addCase(getUserIdFromCompanyId.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getUserIdFromCompanyId.fulfilled, (state, {payload}) => {
            state.loading = false
            state.userAnnouncement = payload
        })
        builder.addCase(getUserIdFromCompanyId.rejected, (state, action) => {
            state.loading = false
            state.errorResult =  action.payload.error
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = companyAnnouncementDetailRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer