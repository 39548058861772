import {
  useEffect,
  useState
} from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import { Button, Popover, notification, Modal } from "antd";
import {
  companyAnnouncementDetailGetById,
  updateViewCompanyAnnouncement,
  reset,
  approveContractFile,
  deleteContract,
  uploadContract,
  getUserIdFromCompanyId
} from "./redux/slice";
//import Icons from "../../../../../../components/Icons";
import NotesList from "../../components/listNotes";
import { ANNOUNCEMENT_STATUS } from "../../../../../../config/helpers/Utils";
import moment from "moment";
import StatusChangesHistory from "../../modals/statusChangesHistory";
import { openStatusChangesHistory, openChangeStatus } from "../../.config/redux/actions";
import ChangeStatus from "../../components/changeStatus";
import { useSecurity } from "../../../../../../config/hooks";
import { P_ANNOUNCEMENT_CONTRACTS, P_ANNOUNCEMENT_NOTES, P_ANNOUNCEMENT_VIEW_HISTORY_CONTRATCS, P_ANNOUNCEMET_STATUS_COLUMN, P_UPDATE_ANNOUNCEMENT_STATUS } from "../../../../../../config/helpers/permissionsHash";
import { InputFile } from "components"
import dayjs from "dayjs";

const CompanyAnnouncementDetailView = () => {
  let { id, companyId } = useParams();
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const navigate = useNavigate();

  const [modal, contextHolderModal] = Modal.useModal();

  const { user } = useSelector((state) => state.authRedux);
  const { data, error, result, resultError } = useSelector(
    (state) => state.companyAnnouncementDetailRedux
  );

  const [upload, setUpload] = useState(null);

  const isFromCompany = companyId;

  useEffect(() => {
    //Redirects to the new view if it is a company with bussinesscode 2 (agent).
    dispatch(getUserIdFromCompanyId({ companyId })).unwrap().then((userId) => {
          if(userId){
            navigate(`/admin/client/dashboard/detail/user/${id}/${userId}`)
          }
        })
  }, [])

  useEffect(() => {
    if(!id || id == 'undefined'){
      navigate("/error");
      return;
    }
    dispatch(
      companyAnnouncementDetailGetById({
        id,
        companyId: isFromCompany,
      })
    );
    if (!user.isAdmin)
      dispatch(updateViewCompanyAnnouncement({ id, viewType: "View" }));

    return () => {
      dispatch(reset());
    };
  }, [companyId, id]);

  useEffect(() => {
    if (result) {
      notification.success({ message: messages["saveSuccessfully"] })
      dispatch(reset({ result: null }))
      setUpload(null);
      dispatch(
        companyAnnouncementDetailGetById({
          id,
          companyId: isFromCompany,
        })
      );
    }

    if (resultError) {
      notification.error({ message: messages["saveError"], description: messages[resultError] || resultError })
      dispatch(reset({ resultError: null }))
    }

  }, [result, resultError]);

  useEffect(() => {
    if (error == 'unAuthorized') {
      navigate("/unauthorized");
    }
  }, [error]);

  // Implementacion de permisos 
  const pShowStatusCol = useSecurity(P_ANNOUNCEMET_STATUS_COLUMN);
  const pChangeStatus = useSecurity(P_UPDATE_ANNOUNCEMENT_STATUS);
  const pShowNotes = useSecurity(P_ANNOUNCEMENT_NOTES);
  const PManageContractsCreate = useSecurity(P_ANNOUNCEMENT_CONTRACTS, ['C']);
  const PManageContractsRead = useSecurity(P_ANNOUNCEMENT_CONTRACTS, ['R']);
  const PManageContractsUpdate = useSecurity(P_ANNOUNCEMENT_CONTRACTS, ['U']);
  const PManageContractsDelete = useSecurity(P_ANNOUNCEMENT_CONTRACTS, ['D']);

  const pShowHistoryContracts = useSecurity(P_ANNOUNCEMENT_VIEW_HISTORY_CONTRATCS);



  const statusAler = () => {
    if (pShowStatusCol && data?.status) {
      let bgcolor = null;
      switch (data?.status) {
        case 1:
          bgcolor = "bg-list-blue";
          break;
        case 2:
          bgcolor = "bg-list-yellow";
          break;
        case 3:
          bgcolor = "bg-list-orange";
          break;
        case 4:
          bgcolor = "bg-list-purple";
          break;
        case 5:
          bgcolor = "bg-list-green";
          break;
        case 6:
          bgcolor = "bg-list-red";
          break;
        default:
          break;
      }

      return (
        <div
          onClick={() =>
            dispatch(
              openStatusChangesHistory({ companyAnnouncementId: data?.id })
            )
          }
          style={{ cursor: "pointer" }}
          className={`alert ${bgcolor}`}
          role="alert"
        >
          <h6 className="alert-heading mb-1 text-white">
            {ANNOUNCEMENT_STATUS[data?.status]}
          </h6>
          <span className="text-white">
            {data?.lastStatus?.updatedOn &&
              moment(data.lastStatus.updatedOn).format("DD/MM/yyyy HH:mm a")}
          </span>
        </div>
      );
    }
    return <></>;
  };

  const handleApproveDocument = () => {
    modal.confirm({
      title: "Approvare?",
      content: "Sei sicuro di voler procedere?",
      okText: messages['confirm']?.toString(),
      cancelText: messages['cancel']?.toString(),
      onOk: () => dispatch(approveContractFile({ id: data.id }))
    })
  }
  const handleDeleteDocument = () => {
    modal.confirm({
      title: "Eliminare?",
      content: "Sei sicuro di voler procedere?",
      okText: messages['confirm']?.toString(),
      cancelText: messages['cancel']?.toString(),
      onOk: () => dispatch(deleteContract({ id: data.id }))
    })

  }
  const handleUploadDocument = () => {
    modal.confirm({
      title: "Aggiornare il nuovo documento?",
      content: "Sei sicuro di voler procedere?",
      okText: messages['confirm']?.toString(),
      cancelText: messages['cancel']?.toString(),
      onOk: () => {
        dispatch(uploadContract({ id: data.id, file: upload.file }))
      }
    })
  }
  return (

    <div className="container-fluid flex-grow-1 container-p-y">
      {statusAler()}
      {contextHolderModal}
      <div className="card mb-4">
        <h5 className="card-header">{messages["dashboardDetail_title"]}</h5>
        <div className="card-body">
          <div className="row">
            <h6 className="card-subtitle mb-2">
              {messages["dashboardDetail_title1"]}:
            </h6>
            {data?.announcement?.description && (
              <div className="col-12 col-xl-12">
                <h6>{data?.announcement?.description}</h6>
              </div>
            )}
            {data?.announcement?.product && (
              <div className="col-12 col-xl-4">
                <small className="text-muted">
                  {messages["dashboardDetail_1"]}
                </small>
                <h6>{data?.announcement?.product}</h6>
              </div>
            )}
            <div className="col-12 col-xl-4">
              <small className="text-muted">
                {messages["dashboardDetail_2"]}
              </small>
              <h6>
                {data?.announcement?.max_price ||
                  messages["dashboardDetail_notSpecified"]}
              </h6>
            </div>
            {/* <div className="col-12">
                    <small className="text-muted">
                      {messages["dashboardDetail_3"]}
                    </small>
                    <h6>{""}</h6>
                  </div> */}
            {/* <div className="col-12 col-xl-6">
                    <small className="text-muted">
                      {messages["dashboardDetail_4"]}
                    </small>
                    <h6>{""}</h6>
                  </div> */}
            {/* <div className="col-12 col-xl-6">
                    <small className="text-muted">
                      {messages["dashboardDetail_5"]}
                    </small>
                    <h6>{""}</h6>
                  </div> */}
            {/*  <div className="col-12 col-xl-6">
                    <small className="text-muted">
                      {messages["dashboardDetail_6"]}
                    </small>
                    <h6>{""}</h6>
                  </div> */}
            {data?.announcement?.definition && (
              <div className="col-12 col-xl-4">
                <small className="text-muted">
                  {messages["dashboardDetail_7"]}
                </small>
                <h6>{data?.announcement?.definition}</h6>
              </div>
            )}
            {/* <div className="col-12">
                    <small className="text-muted">
                      {messages["dashboardDetail_8"]}
                    </small>
                    <h6>{""}</h6>
                  </div> */}
            {data?.announcement?.id && (
              <div className="col-12 col-xl-4">
                <small className="text-muted">
                  {messages["dashboardDetail_9"]}
                </small>
                <h6>{data?.announcement?.id}</h6>
              </div>
            )}
            {true && (
              <div className="col-12 col-xl-4">
                <small className="text-muted">
                  {messages["dashboardDetail_contract"]}
                </small>
                {/* <h6>{data?.announcement?.document?.url}</h6> */}
                {/* se valido la existencia de un documento por primera vez*/}
                <span className="mb-2 d-flex justify-content-between">
                  {PManageContractsRead &&
                    data?.document?.id == null ?
                    <a> {messages["dashboardDetail_emptyHistory"]} </a> :
                    <div className="d-flex align-items-center">
                      <span className="d-flex flex-column">
                        <a href={data?.document?.url} target="_blank" rel="noreferrer">
                          <i className='bx bxs-file-pdf fs-4' />
                          {data?.document?.name}
                        </a>
                        <small>{data?.updatedFileByUser?.fullName} {data?.updatedFileByUser?.company ? `(${data?.updatedFileByUser?.company.name})` : ''}</small>
                      </span>
                      {PManageContractsCreate && data.approvedDocument == false &&
                        <button className="btn btn-label-success btn-sm  mx-2" onClick={handleApproveDocument}>{messages['approve']}</button>
                      }
                      {PManageContractsDelete &&
                        <button className="btn btn-danger btn-sm mx-2" onClick={handleDeleteDocument}>{messages['delete']}</button>
                      }
                    </div>
                  }
                  {pShowHistoryContracts && <Popover
                    trigger="click"
                    content={<ul style={{ listStyle: 'none', padding: 0 }}>
                      {/* {data?.documentHistory} */}
                      {/* se valido la existencia del tamaño del array */}
                      {data?.documentHistory.length > 0 ? data?.documentHistory.map(v => <li className="text-danger border-bottom" key={id}>
                        <span className="me-1">{dayjs(v.date).format('D/MM/YYYY H:m')}</span>
                        <span className="d-flex flex-column ms-2 me-1">
                          <a className="text-danger" href={v?.document?.url} target="_blank" rel="noreferrer">{v?.document?.name}</a>
                          <small>{v?.updatedFileByUser?.fullName} {v?.updatedFileByUser?.company ? `(${v?.updatedFileByUser?.company.name})` : ''}</small>
                        </span>
                        <small>{messages["dashboardDetail_DeletedBy"]} {v?.createdByUser?.fullName}</small>
                        {/* debe crearse un icono visible desde la tabla exterior que deje claro que se ha insertado el pdf / aceptación del cliente. */}
                      </li>) : <a> {messages["dashboardDetail_emptyHistory"]} </a>}
                    </ul>}
                  >
                    <i className='bx bx-history' />
                  </Popover>}
                </span>
                <div>
                  {PManageContractsUpdate &&
                    <div>
                      <InputFile onChange={(value) => setUpload(value)} value={upload} accept=".pdf" />
                      {upload && <button className="btn btn-label-info btn-sm" onClick={handleUploadDocument}>{messages['ok']}</button>}
                    </div>
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <h5 className="card-header">{messages["dashboardDetail_title2"]}</h5>
        <div className="card-body">
          {data && (
            <div className="row">
              <div className="col-12 col-xl-6">
                <small className="text-muted">
                  {messages["dashboardDetail_author1"]}
                </small>
                <h6>
                  {data?.announcement?.name} {data?.announcement?.surname}
                </h6>
              </div>
              <div className="col-12 col-xl-6">
                <small className="text-muted">
                  {messages["dashboardDetail_author2"]}
                </small>
                <h6>{data?.announcement?.province_name}</h6>
              </div>
              <div className="col-12">
                <small className="text-muted">
                  {messages["dashboardDetail_author3"]}
                </small>
                <h6>{data?.announcement?.comuna_name}</h6>
              </div>
              <div className="col-12 col-xl-6">
                <small className="text-muted">
                  {messages["dashboardDetail_author4"]}
                </small>
                <h6>{data?.announcement?.phone}</h6>
              </div>
              <div className="col-12 col-xl-6">
                <small className="text-muted">
                  {messages["dashboardDetail_author5"]}
                </small>
                <h6>{data?.announcement?.email}</h6>
              </div>
              <div className="col-12 col-xl-6">
                <Button
                  icon={<i className="tf-icons bx bxl-whatsapp"></i>}
                  onClick={() => {
                    if (!user.isAdmin) {
                      dispatch(
                        updateViewCompanyAnnouncement({
                          id,
                          viewType: "Whatsapp",
                        })
                      );
                    }
                    window.open(
                      `https://wa.me/39${data?.announcement?.phone}?text=${data?.announcement?.product}`,
                      "_blank",
                      "noreferrer"
                    );
                  }}
                >
                  {messages["dashboardDetail_whatsapp"]}
                </Button>
              </div>
              {/* <div className="col-12 col-xl-6">
                    <small className="text-muted">
                      {messages["dashboardDetail_author6"]}
                    </small>
                    <h6>{""}</h6>
                  </div> */}
            </div>
          )}
        </div>
      </div>
      <StatusChangesHistory
        onChangeStatus={() => {
          dispatch(
            companyAnnouncementDetailGetById({
              id,
              companyId: isFromCompany,
            })
          );
        }}
      />
      {pShowNotes && (
        <NotesList companyAnnouncementId={data?.id} ownerId={data?.companyId} additionalButtons={[
          (pChangeStatus ? <button
            key={0}
            className="btn btn-primary me-sm-3 me-1"
            onClick={() => {
              dispatch(openChangeStatus({
                codeRichiesta: data?.announcement?.id,
                initialValue: data?.status,
                id: data?.id,
              }))
            }}
          >
            {messages["dashboardList_changeStatus"]}
          </button> : <></>)
        ]} />
      )}
      <ChangeStatus.Modal
        refresh={() => {
          dispatch(
            companyAnnouncementDetailGetById({
              id,
              companyId: isFromCompany,
            })
          );
        }}
      />
    </div>
  );
};

export default CompanyAnnouncementDetailView;
