import { Fragment, useEffect, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import moment from "moment";
import {
  Modal,
  Table,
  Input,
  DatePicker,
  Select,
  Tabs,
  notification,
  Alert,
  Popover,
} from "antd";
import { TableOptions } from "components";
//import Icons from "../../../../../../components/Icons";
import { usePaggination, useSecurity } from "../../../../../../config/hooks";

import { companyAnnouncementsListSearch, reset } from "./redux/slice";
import Filters from "../../../../../../components/Filters";
import {
  openStatusChangesHistory,
  openChangeStatus,
} from "../../../../.config/redux/actions";
import { Link } from "react-router-dom";
import {
  ANNOUNCEMENT_STATUS,
  ANNOUNCEMENT_STATUS_OPTIONS,
} from "../../../../../../config/helpers/Utils";
import ChangeStatus from "../../components/changeStatus";
import StatusChangesHistory from "../../modals/statusChangesHistory";
import { getCatalog } from "../../../../../.config/redux/actions";
import {
  P_ANNOUNCEMENT_CONTRACTS,
  P_ANNOUNCEMENT_MAIL_WAS_OPENNED_COLUMN,
  P_ANNOUNCEMENT_STATUS_TABS,
  P_ANNOUNCEMET_STATUS_COLUMN,
  P_ANNOUNCMENT_LASTNOTE_COLUMN,
  P_COMPANY_SELECTOR,
  P_LEAD_MANAGER,
  P_UPDATE_ANNOUNCEMENT_STATUS,
} from "../../../../../../config/helpers/permissionsHash";
import { TextDescriptionCompanies } from "../../../../../../components/TextDescriptionCompanies";
import validatorStatusContract from "../../../announcementsContractManage/helpers/validatorStatusContract";

const CompanyAnnouncementsListView = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const navigate = useNavigate();

  const paggination = usePaggination(companyAnnouncementsListSearch);

  const { user } = useSelector((state) => state.authRedux);
  const { data, loading, error } = useSelector(
    (state) => state.companyAnnouncementsListRedux
  );
  const { companies, loadingCompanies } = useSelector(
    (state) => state.catalogRedux
  );

  const [companyId, setCompanyId] = useState(
    user?.companyId != -1 ? user?.companyId : undefined
  );
  const [businessCode, setBussinesCode] = useState(1);
  const [currentTab, setCurrentTab] = useState(0);
  const [openDescModal, setOpenDescModal] = useState({
    author: "",
    date: "",
    text: "",
    isOpen: false,
  });

  const pCompanySelector = useSecurity(P_COMPANY_SELECTOR);
  const R = useSecurity(P_LEAD_MANAGER, ["R"]);
  const pChangeStatus = useSecurity(P_UPDATE_ANNOUNCEMENT_STATUS);
  const pShowHasOpennedCol = useSecurity(
    P_ANNOUNCEMENT_MAIL_WAS_OPENNED_COLUMN
  );
  const pShowStatusCol = useSecurity(P_ANNOUNCEMET_STATUS_COLUMN);
  const pShowLastNoteCol = useSecurity(P_ANNOUNCMENT_LASTNOTE_COLUMN);
  const pStatusTabsCol = useSecurity(P_ANNOUNCEMENT_STATUS_TABS);
  const PManageContractsRead = useSecurity(P_ANNOUNCEMENT_CONTRACTS, ["R"]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: messages["saveError"]?.toString(),
        description: messages[error] || error.message,
      });
      reset("error");
    }
  }, [error]);

  useEffect(() => {
    if (pCompanySelector) {
      dispatch(
        getCatalog({
          keys: [{ key: "companies", params: { businessCode, all: true } }],
        })
      )
        .unwrap()
        .then((v) => {
          const defaultID = v.companies[0].id;
          // paggination.refresh(null, null, {
          //   companyId: defaultID,
          //   statusId: currentTab,
          // });
          setCompanyId(defaultID);
        });
    }
  }, [businessCode]);

  useEffect(() => {
    if (companyId) {
      paggination.refresh(null, null, {
        companyId: companyId,
        statusId: currentTab,
      });
    }
  }, [companyId]);

  const tabsContent = useMemo(() => {
    const count = data?.countByStatus || {};
    const labelContent = (v) => (
      <span className="d-flex align-center">
        <div className="text-wrap" style={{ width: '7rem'}}>
        {v.label}
        </div>
        {count[v.value] != null && count[v.value] != 0 && (
            <span className="d-inline-flex ps-2 align-items-center ml-2">
                <span className="badge bg-secondary rounded-circle text-white d-flex justify-content-center align-items-center" style={{ width: '25px', height: '25px' }}>
                    {count[v.value]}
                </span>
            </span>
        )}
      </span>
    );
    return [
      { key: 0, label: messages["all"], children: "" },
      ...ANNOUNCEMENT_STATUS_OPTIONS.map((v) => ({
        key: v.value,
        label: labelContent(v),
        children: "",
      })),
    ]
  }, [data])

  const toggleOpenDescModal = (values = {}) => {
    setOpenDescModal({
      author: values?.author,
      date: values?.date,
      text: values?.text,
      isOpen: values?.isOpen,
    });
  };

  // const typeoFNoticationMessage = (item) => {
  //   if (item == 0) {
  //     return {
  //       type: "warning",
  //       message: messages["listNotificacionStatusPending"]
  //     }
  //   } else if (item == 2) {
  //     return {
  //       type: "error",
  //       message: messages["listNotificacionStatusError"]
  //     }
  //   } else {
  //     return {
  //       type: "success",
  //       message: messages["listNotificacionStatusSuccess"]
  //     }
  //   }
  // }

  let columns = [
    {
      width: 10,
      render: (values) => {
        return (
          <TableOptions
            options={[
              {
                show: R,
                label: messages["view"],
                onClick: () => {
                  navigate(
                    `/admin/client/dashboard/detail/${values?.announcementId}${
                      user.isAdmin ? "/" + companyId : ""
                    }`
                  );
                },
              },
              {
                show: pChangeStatus,
                label: messages["dashboardList_changeStatus"],
                onClick: () => {
                  dispatch(
                    openChangeStatus({
                      codeRichiesta: values.announcementId,
                      initialValue: values.status,
                      id: values.id,
                    })
                  );
                },
              },
            ]}
          />
        );
      },
    },
    {
      title: messages["dashboardList_announcementCode"],
      key: "announcementId",
      dataIndex: "announcementId",
      width: 150,
      render: (row, values) => (
        <Link
          target="blank"
          to={`/admin/client/dashboard/detail/${values?.announcementId}${
            user.isAdmin ? "/" + companyId : ""
          }`}
        >
          {row}
        </Link>
      ),
    },
    {
      title: messages["dashboardList_announcementDate"],
      key: "createdOn",
      width: 200,
      //dataIndex: "createdOn",
      render: (row) =>
        row?.createdOn && moment(row.createdOn).format("DD/MM/yyyy HH:mm"),
    },
    {
      title: messages["dashboardList_announcementTitle"],
      key: "announcement",
      dataIndex: "announcement",
      render: (row) => row?.product,
    },
    {
      title: messages["dashboardList_announcementAuthor"],
      key: "author",
      width: 300,
      //dataIndex: "announcement",
      render: (values) => (
        <div className="d-flex justify-content-start align-items-center">
          <div className="w-50">
            <a>
              {`${values?.announcement?.name} ${values?.announcement?.surname} `}{" "}
            </a>
          </div>

          {/* {values?.document?.id && PManageContractsRead && ( */}
          <div className="mx-3">
            <Popover
              trigger="hover"
              content={
                <>
                  {!values?.document && !values?.isRequestingForDocument && (
                    <span>{messages["dashboardList_noDocumentRequest"]}</span>
                  )}
                  {values?.document?.url && (
                    <div>
                      <p>
                        {" "}
                        {
                          messages["dashboardList_AcceptanceInsertedCustomer"]
                        }{" "}
                        {values?.updatedFileByUser?.company?.name == null
                          ? values?.updatedFileByUser?.fullName
                          : values?.updatedFileByUser?.company?.name}{" "}
                      </p>
                      <a
                        className="text-primary"
                        href={values.document.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {messages["dashboardList_seeMore"]}
                      </a>
                    </div>
                  )}
                  {!values?.document && values?.isRequestingForDocument && (
                    <span>{messages["dashboardList_UploadDocument"]}</span>
                  )}
                </>
              }
            >
              {/* <HiDocumentCheck size={40} color="#696cff" /> */}
              {validatorStatusContract(
                values?.isRequestingForDocument,
                values?.document,
                values?.approvedDocument
              )}
            </Popover>
          </div>
        </div>
      ),
    },
    {
      show: pShowStatusCol,
      title: messages["dashboardList_status"],
      key: "Status",
      dataIndex: "status",
      width: 100,
      render: (value, row) => {
        let bgcolor = null;
        switch (value) {
          case 1:
            bgcolor = "bg-list-blue";
            break;
          case 2:
            bgcolor = "bg-list-yellow";
            break;
          case 3:
            bgcolor = "bg-list-orange";
            break;
          case 4:
            bgcolor = "bg-list-purple";
            break;
          case 5:
            bgcolor = "bg-list-green";
            break;
          case 6:
            bgcolor = "bg-list-red";
            break;
          default:
            break;
        }
        return (
          <div
            className={`full-cell-table text-center ${bgcolor}`}
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(
                openStatusChangesHistory({ companyAnnouncementId: row.id })
              );
            }}
          >
            <h6 className="mb-0" style={{ fontSize: 12 }}>
              {`${ANNOUNCEMENT_STATUS[value] || ""}`}
            </h6>
          </div>
        );
      },
    },
    {
      show: pShowLastNoteCol,
      title: messages["dashboardList_lastNote"],
      key: "lastNote",
      //dataIndex: "lastNoteDate",
      render: (row) => (
        <div>
          <p>
            {row?.lastNote?.createdOn &&
              moment(row.lastNote?.createdOn).format("DD/MM/yyyy HH:mm")}
          </p>
          <p> di {row?.numberNotes} note totali </p>
        </div>
      ),
    },
    {
      show: pShowLastNoteCol,
      width: 300,
      title: messages["dashboardList_lastNoteDescription"],
      key: "lastNoteDescription",
      render: (values) => (
        <div>
          {values?.lastNote?.note && (
            <>
              <p className="table-text-overflow">{values?.lastNote?.note}</p>
              <a
                style={{ color: "#696cff" }}
                onClick={() =>
                  toggleOpenDescModal({
                    author:
                      values?.lastNote?.created.firstName +
                      " " +
                      values?.lastNote?.created.lastName,
                    date: values?.lastNote?.createdOn,
                    text: values?.lastNote?.note,
                    isOpen: true,
                  })
                }
              >
                {messages["dashboardList_seeMore"]}
              </a>
              <div>
                {`${values?.totalProgrammedNotes.total} Promemoria `}
                <br />
                {`${values?.totalProgrammedNotes.totalSent} Inviati ( ${values?.totalProgrammedNotes.totalSentByAgents} Agente -  ${values?.totalProgrammedNotes.totalSentByAdmins} Admins )`}
                <br />
                {`
                   ${values?.totalProgrammedNotes.totalPending} In attessa ( ${values?.totalProgrammedNotes.totalPendingByAgents} Agente -  ${values?.totalProgrammedNotes.totalPendingByAdmins} Admins )
                  `}
              </div>
            </>
          )}
        </div>
      ),
    },
    // {
    //   show: pShowHasOpennedCol,
    //   title: messages["dashboardList_announcementHasOpen"],
    //   key: "hasOpen",
    //   render: (row) => (row?.hasOpen ? "SI" : "NO"),
    // },
    {
      show: pShowHasOpennedCol,
      key: "status",
      width: 50,
      render: (values) => {
        if (values?.totalProgrammedNotes.totalPending > 0) {
          return (
            <Alert
              message={messages["listNotificacionStatusPending"]}
              type={"warning"}
              showIcon
            />
          );
        } else {
          return;
        }
      },
    },
    // {
    //   title: messages["dashboardList_announcementContactCount"],
    //   key: "contactCount",
    //   //dataIndex: "contactCount",
    //   render: (row) => row?.contactCount,
    // },
    // {
    //   title: messages["dashboardList_announcementViewCount"],
    //   key: "viewCount",
    //   //dataIndex: "viewCount",
    //   render: (row) => row?.viewCount,
    // },
  ];

  columns = columns.filter((v) => v?.show === undefined || v?.show);

  return (
    <Fragment>
      <div className="container-fluid flex-grow-1 container-p-y">
        <div className="card card-body">
          {pCompanySelector && (
            <div className="row  mb-2">
              <div className="col-md-3 col-sm-12 mb-2">
                <Select
                  style={{ width: "100%" }}
                  value={businessCode}
                  size="large"
                  onChange={(value) => {
                    setCurrentTab(0);
                    setBussinesCode(value);
                  }}
                  options={[
                    // { value: 2, label: messages["sidebar_Agenti"] },
                    { value: 0, label: messages["sidebar_lista1"] },
                    { value: 1, label: messages["sidebar_list2"] },
                  ]}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <Select
                  style={{ width: "100%" }}
                  value={companyId}
                  loading={loadingCompanies}
                  size="large"
                  onChange={(value) => {
                    setCompanyId(value);
                  }}
                  options={
                    companies &&
                    companies?.map((v) => ({
                      value: v.id,
                      label: (
                        <span
                          className={
                            v.isDeleted
                              ? "badge bg-label-danger text-dark"
                              : "badge bg-label-success text-dark"
                          }
                        >
                          {v.id} - {v.name}
                        </span>
                      ),
                    }))
                  }
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-4 col-sm-12">
              {R && (
                <Input
                  type="text"
                  className="form-control my-1 w-100"
                  placeholder={messages["search"]}
                  onChange={paggination.onSearchChange}
                  value={paggination.searchText}
                />
              )}
            </div>
            <div className="col-md-8 col-sm-12">
              {R && (
                <div className="text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap">
                  <Filters
                    width={300}
                    onApply={(values) => {
                      paggination.refresh(null, null, {
                        ...values,
                        companyId: companyId,
                        statusId: currentTab,
                      });
                    }}
                  >
                    <Filters.Item
                      name="filterSendDate"
                      label={messages["campaignsList_date"]}
                    >
                      <DatePicker.RangePicker placement="bottomRight" />
                    </Filters.Item>
                  </Filters>
                  <button
                    className="btn btn-primary m-1"
                    onClick={() => {
                      paggination.refresh();
                    }}
                  >
                    {messages["refresh"]}
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="col-md-8 col-sm-12 mt-4 mb-4">
            <TextDescriptionCompanies />
          </div>

          <div className="mt-4 table-responsive">
            {pStatusTabsCol && (
              <Tabs
                size="small"
                type="card"
                onChange={(c) => {
                  paggination.refresh(null, null, { companyId, statusId: c });
                  setCurrentTab(c);
                }}
                items={tabsContent}
              />
            )}
            {R && (
              <Table
                rowKey="id"
                loading={loading}
                columns={columns}
                dataSource={data?.items || []}
                pagination={{
                  total: data?.count || 0,
                  ...paggination.tablePaggination,
                }}
              />
            )}
          </div>
        </div>
      </div>
      <StatusChangesHistory />
      <ChangeStatus.Modal
        refresh={() => {
          paggination.refresh(null, null, {
            companyId,
            statusId: currentTab,
          });
        }}
      />
      <Modal
        open={openDescModal.isOpen}
        width={800}
        onCancel={() => {
          toggleOpenDescModal({
            isOpen: false,
          });
        }}
        footer={null}
        maskClosable={false}
      >
        <div className="p-3 p-md-5">
          <h3>{openDescModal.author}</h3>
          <p>{moment(openDescModal.date).format("DD/MM/yyyy HH:mm")}</p>
          <p>{openDescModal.text}</p>
        </div>
        <div className="row pt-2 ">
          <div className="col-12 text-center">
            <button
              className="btn btn-label-secondary"
              onClick={() =>
                toggleOpenDescModal({
                  isOpen: false,
                })
              }
            >
              {messages["close"]}
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default CompanyAnnouncementsListView;
