import { Button, Form, Input, Modal, Select, notification } from 'antd';
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux'
import { Loading, InputFile } from '../../../../../../components';
import { documentsAndContractsEditClose, documentsAndContractsEditSave, reset } from './.config/redux/slice';
import { getDocumentsList } from '../../.config/redux/actions';
import { AiFillFilePdf } from 'react-icons/ai';
import { getCatalog } from '../../../../../.config/redux/actions';

export const EditDocumentModal = ({ onClose }) => {

    const dispatch = useDispatch();
    const { messages } = useIntl();
    const [form] = Form.useForm();
    const [id, setId] = useState();
    const [files, setFiles] = useState([]);
    const { loading, isOpen, data, result, error, } = useSelector(state => state.documentsAndContractsEdittRedux)
    const { roles } = useSelector((state) => state.catalogRedux);


    // verficacion de cargar de usuario
    useEffect(() => {
        if (data && !id) {
            // se carga la info al form solo una vez
            setId(data.id);
            form.setFieldsValue({
                //...data,
                name: data?.name,
                note: data?.note,
                visibleTo: data?.visibleTo
                //files: data.files
            })
            // setFiles(data?.files)
        }

        if (result) {
            dispatch(reset({ result: null }))
            notification.success({ message: messages["saveSuccessfully"] })
            closeModal(true)
        }

        if (error) {
            dispatch(reset({ error: null }))
            notification.error({ message: messages["saveError"], description: messages[error] || error })
        }

    }, [data, result, error])

    useEffect(() => {
        dispatch(getCatalog({ keys: ["roles"] }));
    }, []);

    //console.log(roles, "implementamos los roles que queremos mostrar en las options ");

    // console.log('esta abierto??', isOpen)
    //se valida y se limpia todo lo que pueda causar errores
    const closeModal = useCallback((refresh) => {
        dispatch(getDocumentsList());
        setId()
        form.resetFields()
        dispatch(documentsAndContractsEditClose())
        onClose && onClose(refresh)
    })

    // añade un file a la note
    // const addFileToNote = (file) => {
    //     console.log('se sube el evento', file)
    //     if (files.length === 1) {
    //         return;
    //     }
    //     setFiles([...files, file]);
    // }

    // elimina un file de la nota
    const onDeteleFileToNote = (index) => {
        if (files?.length) {
            let newValues = files.filter((v, i) => index !== i);
            setFiles(newValues);
        }
    }

    return (
        <div>
            <Modal
                open={isOpen}
                width={800}
                onCancel={() => { closeModal() }}
                footer={null}
                maskClosable={false}
            >
                <div className="p-3 p-md-5">
                    <div className='row'>
                        <div className="text-center mb-4">
                            <h3>
                                {messages["submitDocument_title"]}
                            </h3>
                        </div>
                        <Loading loading={!!loading}>
                            <div className='card-body'>
                                <Form
                                    form={form}
                                    layout='vertical'
                                    onFinish={(values) => {
                                        dispatch(documentsAndContractsEditSave({
                                            id: Number(id),
                                            name: values.name,
                                            note: values.note,
                                            files: data.files,
                                            newFiles: values.files,
                                            visibleTo: values.visibleTo,
                                            ...values,
                                        }))
                                    }}
                                >
                                    <div className="row g-3">
                                        <div className="col-12">
                                            <Form.Item
                                                name='name'
                                                label={<label className="form-label">{messages["submitDocument_name"]}</label>}
                                                rules={[{ required: true, message: messages['required_field'] }]}
                                            >
                                                <Input className="form-control" placeholder={messages["submitDocument_placeholderDocumentName"]} />
                                            </Form.Item>
                                        </div>
                                        <div className='col-md-12'>
                                            <Form.Item
                                                name="note"
                                                label={<label className="form-label">{messages["submitDocument_note"]}</label>}
                                            >
                                                <Input.TextArea
                                                    maxLength={100}
                                                    rows={2}
                                                    className="form-control"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className="row">
                                                <h6 className="card-subtitle mb-2"> {messages["companiesDetailNotes_addFilesDescription"]}</h6>
                                                <div className="col-12">

                                                    {
                                                        id &&
                                                        data?.files?.map((f, i) => (
                                                            <a className='d-flex flex-row align-items-center' key={i} href={f?.url} rel="noreferrer" target="_blank">
                                                                <AiFillFilePdf size={30} />
                                                                <p className='m-2'>{f?.name}</p>
                                                            </a>
                                                        ))
                                                    }


                                                    <Form.Item
                                                        name='files'
                                                    >
                                                        <InputFile

                                                            // value={files}
                                                            // onChange={addFileToNote}
                                                            accept=".png, .jpg, .jpeg, application/pdf"
                                                            onDelete={onDeteleFileToNote}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <Form.Item
                                                name='visibleTo'
                                                label={
                                                    <label className="form-label">{messages["companiesEdit_dateContracted"]}</label>}
                                            >
                                                <Select
                                                    mode='multiple'
                                                    showSearch
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    options={
                                                        roles?.length
                                                        ? roles.map((v) => ({
                                                              label: v.name,
                                                              value: v.id,
                                                          }))
                                                        : []
                                                    }
                                                >

                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Loading>
                        <div className='row pt-5'>
                            <div className='col-12 text-center'>
                                <Button
                                    disabled={loading}
                                    className='btn btn-primary me-sm-3 me-1'
                                    onClick={() => form.submit()}
                                    loading={loading}
                                >
                                    {messages["save"]}
                                </Button>
                                <button
                                    className="btn btn-label-secondary"
                                    onClick={() => closeModal()}
                                >
                                    {messages["cancel"]}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )

}
