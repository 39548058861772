import { InputNumber } from "antd"
import { useMemo } from "react"

const NumberInput = (props) => {
    const { max, min, require, defaultValue, decimalsNumber } = props
    const defaultProps = useMemo(() => {
        return { max, min, require, defaultValue, decimalsNumber }
    }, [props])
    return (
        <div className="row">
            <InputNumber {...defaultProps} />
        </div>
    )
}

export default NumberInput
