import { createSlice } from "@reduxjs/toolkit"
import { clientMutation } from '../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../config/constants';
import { ExceptionManager } from '../../../../config/helpers/Logging'
import { resetALL } from '../../../../config/redux/actions'
import axios from "axios";

//STATE INITIAL
const INIT_STATE = {
    loadingUploadFile: false,
    uploadResult: null,
    error: null
};

const route = 'common/files/redux/slice'

export const uploadFile = async ({ file, hash, entityName, entityId }) => {
    try {
        console.log("file", file)
        //Registro en BASE DE DATOS
        let result = await clientMutation(
            `
                mutation LOG_FILE($fileName: String!, $entityId: String!, $entityName: String!, $uuidKey: String) {
                    data: createSystemFile(fileName: $fileName, entityId: $entityId, entityName: $entityName, uuidKey:  $uuidKey) {
                        entityId
                        entityName
                        extension
                        uuidKey
                        name
                        url
                    }
                }
            `,
            {
                fileName: file.name,
                uuidKey: hash,
                entityId,
                entityName
            },
            endpoints.GRAPHQL_GENERAL,
        )

        if (!result.data?.url) {
            throw 'File upload problem'
        }

        console.log("signedData result", result.data)
        //Subir archivo a servidor
        const formData = new FormData();
        formData.append('file', file);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'bearer ' + localStorage.getItem('token'),
                'uuidKey': result.data.uuidKey
            }
        };

        await axios.put(endpoints.FILE_PUT, formData, config)

        return result.data

    } catch (exc) {
        ExceptionManager(exc, route, 'saveEditCatalog');
        throw exc
    }
}

//Slice
const commonFileRedux = createSlice({
    name: 'common/files',
    initialState: INIT_STATE,
    reducers: {
        catalogLoadings(state, action) {
            return { ...state, ...action.payload }
        },
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        // builder.addCase(uploadFile.pending, (state) => {
        //     state.loadingUploadFile = true
        // })
        // builder.addCase(uploadFile.fulfilled, (state, { payload }) => {
        //     return { ...state, loadingUploadFile: false, ...payload }
        // })
        // builder.addCase(uploadFile.rejected, (state, action) => {
        //     state.loadingUploadFile = false
        //     state.error = action.error.message
        // })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = commonFileRedux

// Extract and export each action creator by name
export const { reset, catalogLoadings } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer