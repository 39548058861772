import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientQuery, clientMutation } from '../../../../../../../config/helpers/GraphQLApi';

import { endpoints } from '../../../../../../../config/constants';
import { ExceptionManager } from '../../../../../../../config/helpers/Logging'
import { resetALL } from '../../../../../../../config/redux/actions'
import { uploadFile } from "../../../../../../common/files/redux/slice";
import { v4 } from "uuid";

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: [],
    result: null,
    error: null
};

const route = 'modules/management/users/views/detail/components/notes/slice'

//Extend async Action
export const searchUserNotes = createAsyncThunk('users/detail/payments/searchNotes', async (
    { userId, searchText, limit, offset },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientQuery(
            `query QUERY($userId: Int!, $searchText: String, $limit: Int, $offset: Int){
                notes: searchUserNotesByUserId(userId: $userId, searchText: $searchText, limit: $limit, offset: $offset) {
                    items {
                        id
                        note,
                        files
                        createdBy
                        createdOn
                        createdByUser {
                            id
                            fullName
                            email
                        }
                    }
                    count
                }
            }`,
            {
                userId: Number(userId),
                searchText: searchText || '',
                limit: limit || 10,
                offset: offset || 0
            },
            endpoints.GRAPHQL_GENERAL
        );
        return data?.notes || []

    } catch (exc) {
        ExceptionManager(exc, route, 'usersDetailSearchNotes', dispatch);
        return rejectWithValue(exc)
    }
})

export const userNoteSave = createAsyncThunk('users/detail/notes/save', async (
    {
        id, userId, note, files
    },
    { rejectWithValue, dispatch }
) => {

    try {
        let data
        let signedData = null
        let fileId  = v4()
        if(files){
            signedData = await uploadFile({ file: files[0].file, hash: fileId, entityName: "user_notes", entityId: id || fileId })
        }
        if (id) {
            // data = await clientMutation(
            //     `mutation updateCompany ($id: Int!, $email: String!, $firstName: String, $lastName: String, $name: String, $paridaIVA: String, $state: Int, $city: String,
            //         $address: String, $phone1: String, $phone2: String, $workState: [Int], $workActivity: String, $paymentMethod: String, $price: String,
            //         $notes: String, $freeStartDate: Datetime, $freeEndDate: Datetime, $payStartDate: Datetime, $payEndDate: Datetime) {
            //         updateCompany (id: $id, email: $email, firstName: $firstName, lastName: $lastName, name: $name, paridaIVA: $paridaIVA, state: $state, city: $city,
            //             address: $address, phone1: $phone1, phone2: $phone2, workState: $workState, workActivity: $workActivity, paymentMethod: $paymentMethod, price: $price,
            //             notes: $notes, freeStartDate: $freeStartDate, freeEndDate: $freeEndDate, payStartDate: $payStartDate, payEndDate: $payEndDate) {
            //             id
            //         }
            //     }`,
            //     {
            //         id, email, firstName, lastName, name, paridaIVA, state, city, address, phone1, phone2, workState,
            //         workActivity, paymentMethod, price, notes, freeStartDate, freeEndDate, payStartDate, payEndDate
            //     },
            //     endpoints.GRAPHQL_GENERAL
            // );

            // return data.updateCompany.id

        }
        else {
            //create
            data = await clientMutation(
                `mutation createUserNote ($userId: Int!, $note: String!, $files: [InputFile]) {
                    result: createUserNote (userId: $userId, note: $note, files: $files) {
                        id
                    }
                }`,
                {
                    userId, 
                    note,
                    files: [{
                        id: signedData.uuidKey,
                        uuidKey:  signedData.uuidKey,
                        url: signedData.url,
                        name: signedData.name,
                        type: signedData.extension
                    }]
                },
                endpoints.GRAPHQL_GENERAL
            );

            return data.result.id

        }

    } catch (exc) {
        ExceptionManager(exc, route, 'usersDetailNotesSave', dispatch);
        return rejectWithValue(exc)
    }
})

export const deleteUserNoteById = createAsyncThunk('users/detail/notes/deleteNoteById', async (
    { id },
    { rejectWithValue, dispatch }
) => {
    try {
        let data = await clientMutation(
            `mutation deleteUserNote ($id: Int!) {
                result: deleteUserNote (id: $id)
            }`,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );
        return data.result

    } catch (exc) {
        ExceptionManager(exc, route, 'deleteNoteById', dispatch);
        return rejectWithValue(exc)
    }
})
//Slice
const usersNotesRedux = createSlice({
    name: 'users/notes',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(searchUserNotes.pending, (state) => {
            state.loading = true
        })
        builder.addCase(searchUserNotes.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
        })
        builder.addCase(searchUserNotes.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

        //thunk save
        builder.addCase(userNoteSave.pending, (state) => {
            state.loading = true
            state.result = null
            state.error = null
        })
        builder.addCase(userNoteSave.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(userNoteSave.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

        //thunk delete
        builder.addCase(deleteUserNoteById.pending, (state) => {
            state.loading = true
            state.result = null
            state.error = null
        })
        builder.addCase(deleteUserNoteById.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(deleteUserNoteById.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = usersNotesRedux

// Extract and export each action creator by name
export const { reset } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer