import { Checkbox, Input, InputNumber } from 'antd';

function TextProps(props) {
    const { values, onChange } = props;
    return (
        <div className='col-12'>
            <div className='row mb-2'>
                <label className="form-label">Default value</label>
                <Input value={values['defaultValue']} onChange={e => onChange('defaultValue')(e.target.value)} placeholder='Default Value'/>
            </div>
            <div className='row mb-2'>
                <Checkbox onChange={(e) => onChange('textArea')(e.target.checked)} checked={values['textArea']}>Text area</Checkbox>
            </div>
            <div className='row mb-2'>
                <label className="form-label">Max length</label>
                <InputNumber value={values['maxLength']} onChange={onChange('maxLength')} placeholder='Max length' min={0} max={500}/>
            </div>
            <div className='row mb-2'>
                <label className="form-label">Regular expression</label>
                <Input value={values['regExp']} onChange={e => onChange('regExp')(e.target.value)} placeholder='Regular expression'/>
            </div>
        </div>
    )
}

export default TextProps