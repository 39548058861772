import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Form, Select, notification, Modal } from "antd";

import { ANNOUNCEMENT_STATUS_OPTIONS } from "../../../../../../config/helpers/Utils";
import {
  clearUpdateValues,
  updateStatusCompanyAnnouncement,
  closeChangeStatus
} from "./redux/slice";

function ChangeStatus(props) {
  const { refresh, initialValue: _initialValue, id: _id,  showNotification = true, onClose } = props;
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const [form] = Form.useForm();

  const { updateResult,  error, initialValue, id } = useSelector(
    (state) => state.companyAnnouncementChangesRedux
  );

  useEffect(() => {
    form.setFieldValue("status", (_initialValue || initialValue));
  }, [_initialValue, initialValue]);

  useEffect(() => {
    if (error && showNotification) {
      notification.error({
        message: messages["saveError"],
        description: messages[error] || error,
      });
    }
    if (updateResult && showNotification) {
      notification.success({
        message: messages["saveSuccessfully"],
      });
      refresh && refresh();
      dispatch(clearUpdateValues());
    }
  }, [updateResult, error]);

  return (
    <div>
      <div className="card-body">
        {/* {codeRichiesta && (
          <div className="row">
            <p className="text-muted mr-2" style={{ lineHeight: 1.1 }}>
              {messages["dashboardList_announcementCode"]}:
            </p>
            <h6>{codeRichiesta}</h6>
          </div>
        )} */}
        <Form
          form={form}
          layout="vertical"
          onValuesChange={(values) => {
            if (values?.status && values?.status !== (_initialValue ? _initialValue : initialValue)) {
              dispatch(
                updateStatusCompanyAnnouncement({ id: _id? _id : id, status: values.status })
              );
              dispatch(closeChangeStatus())
              onClose && onClose();
            }
          }}
        >
          <div className="col-12 mb-2">
            <Form.Item
              name="status"
              label={<label className="form-label">{messages["dashboardList_status"]}</label>}
              required
            >
              <Select className="w-100" options={ANNOUNCEMENT_STATUS_OPTIONS} />
            </Form.Item>
          </div>
        </Form>

        {/* <div className="row pt-3">
            <div className="col-12 text-end">
              <button className="btn btn-label-secondary me-1" disabled={loadingUpdate} onClick={() => {
                  new Dropdown(ref.current).toggle()
                  form.setFieldValue("status", initialValue)
                }}>
                {messages["cancel"]}
              </button>
              <button className="btn btn-primary" disabled={loadingUpdate} onClick={() => form.submit()}>
                {messages["apply"]}
              </button>
            </div>
          </div> */}
      </div>
    </div>
  );
}
ChangeStatus.Modal = (props) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const { isOpen, codeRichiesta } = useSelector(
    (state) => state.companyAnnouncementChangesRedux
  );
  
  return (
    <Modal
      open={isOpen}
      title={<h6>{codeRichiesta}</h6>}
      width={300}
      onCancel={() => {
        dispatch(closeChangeStatus())
        onClose && onClose();
      }}
      footer={null}
      maskClosable={false}
    >
      <ChangeStatus {...props} />
    </Modal>
  );
};
export default ChangeStatus;
