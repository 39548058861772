import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ExceptionManager } from "../../../../../../../../config/helpers/Logging";
import { endpoints } from "../../../../../../../../config/constants";
import { resetALL } from "../../../../../../../../config/redux/actions";
import { clientMutation, clientQuery } from "../../../../../../../../config/helpers/GraphQLApi";
import { v4 } from "uuid";
import { uploadFile } from "../../../../../../../common/files/redux/slice";


//STATE INITIAL
const INIT_STATE = {
    businessCode: 0,
    loading: false,
    isOpen: false,
    data: null,
    result: null,
    error: null
};


const route = 'modules/client/documentsAndContracts/modals/config/redux/slice'

//Extend async Action
export const documentsAndContractsEditOpen = createAsyncThunk('documents/edit/open', async ({ id }, { rejectWithValue, dispatch }) => {

    try {
        //si es una creacion se retorna
        if (!id) {
            // console.log('se valida que es una creacion')
            return { data: null }
        }

        // Si es edicion, se obtiene la data
        let data = await clientQuery(
            `query getDocumentContractById($id: Int!){
                document: getDocumentContractById(id: $id){
                    id
                    note
                    name
                    files{
                        url
                        uuidKey
                        name
                        id
                        type
                    }
                    visibleTo
                }
            }`,
            {
                id: Number(id)
            },
            endpoints.GRAPHQL_GENERAL
        )
        return data?.document

    } catch (exc) {
        //Se guarda la excepcion
        ExceptionManager(exc, route, 'documentsAndContractsEditOpen', dispatch);
        return rejectWithValue(exc)
    }

});


export const documentsAndContractsEditSave = createAsyncThunk('documents/edit/dave', async (
    {
        id, name, note, files, visibleTo, newFiles
    },
    { rejectWithValue, dispatch }
) => {
    try {
        let data

        let signedData = null;
        let fileId = v4()
        console.log(newFiles)
        if(newFiles){
            let fileToUpload = {
                file: newFiles.file,
                entityName: 'documentsAndContracts',
                entityId: `${id}` || fileId,
            }
            let currentFile = files[0];
            if(currentFile){
                fileToUpload.hash = currentFile?.uuidKey || currentFile?.id
            } else {
                fileToUpload.hash = fileId
            }
            signedData = await uploadFile(fileToUpload)
        }

        if (id) {
            // Editamos un documento existente 
            data = await clientMutation(
                `mutation updatedDocumentContract($id: Int!, $name: String, $note: String, $files: [InputFile], $visibleTo: [Int] ){ updatedDocumentContract( id: $id, name: $name, note: $note,files: $files, visibleTo: $visibleTo)
                    {
                        id
                    }
                }`,
                {
                    id, name, note, visibleTo,  
                    files: signedData ? [{
                        id: signedData.uuidKey,
                        uuidKey:  signedData.uuidKey,
                        url: signedData.url,
                        name: signedData.name,
                        type: signedData.extension
                    }] : undefined
                },
                endpoints.GRAPHQL_GENERAL
            );
            console.log('data del slice edicion', { data });
            return data?.updatedDocumentContract.id
        } else {
            // creamos un nuevo documento
            data = await clientMutation(
                `mutation createDocumentContract( $name: String!,$note: String,$files: [InputFile]!, $visibleTo: [Int]){
                createDocumentContract (
                    name: $name, note: $note,files: $files, visibleTo: $visibleTo
                )
                {
                    id
                }
            }, 
            `,
                {
                    name, note,  visibleTo,
                    files: [{
                        id: signedData.uuidKey,
                        uuidKey:  signedData.uuidKey,
                        url: signedData.url,
                        name: signedData.name,
                        type: signedData.extension
                    }]
                },
                endpoints.GRAPHQL_GENERAL
            );
            // console.log('creamos un nuevo documento', { data })
            return data?.createDocumentContract.id
        }
    } catch (error) {
        ExceptionManager(error, route, 'documentsAndContractsEditSave', dispatch);
        return rejectWithValue(error)
    }
});


//Action Delete documents
export const deleteDocumentContract = createAsyncThunk('companies/list/deleteDocumentContract', async ({ id }, { rejectWithValue, dispatch }) => {
    
    try {
        const result = await clientMutation(
            `mutation deleteDocumentContract ($id: Int!) {
                deleteDocumentContract(id: $id)
            }`,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL
        );
        return result;
    } catch (exc) {
        ExceptionManager(exc, route, 'deleteDocumentContract', dispatch);
        return rejectWithValue(exc)
    }
})



// Slice
const documentsAndContractsEdittRedux = createSlice({
    name: 'documentsAndContracts/edit',
    initialState: INIT_STATE,
    reducers: {
        documentsAndContractsEditClose(state, action) {
            return INIT_STATE
        },
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        //thunk editOpen
        builder.addCase(documentsAndContractsEditOpen.pending, (state) => {
            state.loading = true
            state.isOpen = true
        })
        builder.addCase(documentsAndContractsEditOpen.fulfilled, (state, { payload }) => {
            state.loading = false
            state.data = payload
            state.businessCode = payload.businessCode
        })
        builder.addCase(documentsAndContractsEditOpen.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

        //thunk save
        builder.addCase(documentsAndContractsEditSave.pending, (state) => {
            state.loading = true
            state.result = null
            state.error = null
        })
        builder.addCase(documentsAndContractsEditSave.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(documentsAndContractsEditSave.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })

        //thunk Delete
        builder.addCase(deleteDocumentContract.pending, (state) => {
            state.loading = true
            state.result = null
            state.error = null
        })
        builder.addCase(deleteDocumentContract.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(deleteDocumentContract.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = documentsAndContractsEdittRedux;

// Extract and export each action creator by name
export const { documentsAndContractsEditClose, reset } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;