import { useMemo } from "react"
import { Input } from "antd";

function TextInput(props) {
  const {defaultValue , textArea, maxLength, regExp} = props
  const defaultProps = useMemo(() => {
    return {defaultValue, maxLength }
  }, [props])
  return (
    <div className='row'>
        {textArea ? <Input.TextArea {...defaultProps}/> : <Input {...defaultProps}/>}
    </div>
  )
}

export default TextInput