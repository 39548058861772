export const ValidateRoles = (roles, user) => {
    let result = false;
    if (!roles)
        result = true;
    else
        roles.forEach(x => {
            if (user.roles.find(r => r.id == x))
                result = true
        })

    return result
}

export const RemoveSpaces = (text) => {

    return text.replace(' ', '_')
}


export const OpenFile = (data) => {

    if (typeof data === 'string')
        window.open(data, '_blank');
    else {
        if (navigator.userAgent.match('CriOS')) {
            let fileData = [data];
            var reader = new FileReader();
            var blob = new Blob(fileData, { type: data.type });
            reader.onload = function (e) {
                window.location.href = reader.result;
            }
            reader.readAsDataURL(blob);
        }
        // for IE and edge browser
        else if (window.navigator.msSaveOrOpenBlob) {
            let fileData = [data];
            let blobObject = new Blob(fileData);
            window.navigator.msSaveBlob(blobObject);
        }
        //for all other browser
        else {
            let fileData = [data];
            var blob = new Blob(fileData, { type: data.type });
            let url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        }
    }
}

export const ANNOUNCEMENT_STATUS = {
    1: "In corso",
    2: "Sopralluogo fissato",
    3: "In consegna preventivo",
    4: "In attesa di esito",
    5: "Chiuso con successo",
    6: "Morto"
}
export const ANNOUNCEMENT_STATUS_OPTIONS = [
    {value: 1, label: "In corso",},
    {value: 2, label: "Sopralluogo fissato",},
    {value: 3, label: "In consegna preventivo",},
    {value: 4, label: "In attesa di esito",},
    {value: 5, label: "Chiuso con successo",},
    {value: 6, label: "Morto"},
]