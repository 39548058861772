import { DatePicker } from "antd"


export const DateInput = (props) => {

    const { dateRange, futureDates, typeData } = props
    const { RangePicker } = DatePicker;

    return (
        <div className="row">
            {
                dateRange == true ?
                    <RangePicker />
                    : <DatePicker />
            }
        </div>
    )
}
