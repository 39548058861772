
import { useState, useCallback, useEffect } from 'react'
import { Button, Form, Input, Alert, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Loading } from '../../../../components/Loading'
import { captcha } from '../../../../config/constants'
import { OTPInput } from '../../../../components/OTPInput';
import { GetErrorMessage } from '../../../../config/helpers/Logging';
import Footer from '../../../layouts/footer'

import { loginSingIn, loginOTP, loginOTPResend, reset } from './redux/slice'

import '../../../../assets/sass/pages/page-auth.scss'

const LoginViewInternal = () => {

    const [form] = Form.useForm()
    const [formOtp] = Form.useForm()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const intl = useIntl()

    const { messages } = intl
    const { executeRecaptcha } = useGoogleReCaptcha();

    let { u, h } = useParams();

    const redirect = searchParams.get('redirect')

    useEffect(() => {

        if (u && h) {
            dispatch(loginSingIn({ username: u, password: h, navigate, redirect, isHash: true }))
        }

    }, [u, h])


    const [errorMessage, setErrorMessage] = useState()
    const [otpInfo, setOtpInfo] = useState()


    const { loading, result } = useSelector(state => state.loginRedux)


    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('yourAction');
        // Do whatever you want with the token
        return token
    }, [executeRecaptcha]);

    useEffect(() => {
        if (result) {

            console.log("result", result)
            if (result.error) {
                setErrorMessage(GetErrorMessage(result.error, intl))
                //setErrorMessage(result.error.innerError.stack)
            }
            else if (result.code == "LOGIN_VALIDATE_OTP") {
                setOtpInfo(result.data)
                notification.open({
                    message: messages['login_otp_title'],
                    description: `${messages['login_otp_description']} - ${result.data.email}`
                })
            }

            dispatch(reset({ result: null }))
        }
    }, [result])

    return (
        // <GoogleReCaptchaProvider reCaptchaKey={captcha.REACT_APP_SITE_KEY}>
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner">
                    <div className="card">
                        <div className="card-body">
                            <div className="app-brand justify-content-center">
                                <a href="index.html" className="app-brand-link gap-2">
                                    <span className="app-brand-logo">
                                        <img src='/assets/images/logo_tet.jpg' />
                                    </span>
                                    {/* <span className="app-brand-text demo text-body fw-bolder">EDILNET</span> */}
                                </a>
                            </div>
                            {/* <h4 className="mb-2">{messages["login_welcome_message"]}</h4> */}
                            <p className="mb-4">{messages["login_welcome_message"]}</p>
                            {
                                errorMessage &&
                                <div className="mb-3 row">
                                    <div className="col-12">
                                        <Alert
                                            message={errorMessage}
                                            type="error"
                                            closable
                                            onClose={() => setErrorMessage()}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                otpInfo
                                    ?
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="container height-100 d-flex justify-content-center align-items-center">
                                                <Form
                                                    form={formOtp}
                                                    className="position-relative"
                                                    onFinish={(values) => {
                                                        // const token = handleReCaptchaVerify()
                                                        // if (token) {
                                                        setErrorMessage()
                                                        dispatch(loginOTP({ id: otpInfo.guid, code: values.code, navigate }))
                                                        // }
                                                        // else {
                                                        //     setErrorMessage("No se ha podido validar la autorizacion por Captcha, intente nuevamente")
                                                        // }
                                                    }}
                                                >
                                                    <div className="pb-3 text-center">
                                                        <h5>{messages['login_otp_title']}</h5>
                                                        <div>
                                                            <span>{messages['otp_description']}</span> <span>{otpInfo.email}</span>
                                                        </div>

                                                        <Form.Item
                                                            name="code"
                                                            rules={[{ required: true, message: messages["required_field"] }]}
                                                        >
                                                            <OTPInput />
                                                        </Form.Item>
                                                    </div>
                                                    <div className="pb-1">
                                                        <Button className="btn btn-primary w-100" onClick={() => { formOtp.submit() }}>{messages["validate"]}</Button>
                                                    </div>
                                                    <div className="pb-3">
                                                        <Button className="btn btn-primary w-100" onClick={() => { dispatch(loginOTPResend({ id: otpInfo.guid })) }}>{messages['resend']}</Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row">
                                        <Loading loading={loading}>
                                            <Form
                                                form={form}
                                                name="basic"
                                                layout="vertical"
                                                initialValues={{ remember: true }}
                                                onFinish={(values) => {
                                                    // const token = handleReCaptchaVerify()
                                                    // if (token) {
                                                    setErrorMessage()
                                                    dispatch(loginSingIn({ ...values, navigate, redirect }))
                                                    // }
                                                    // else {
                                                    //     setErrorMessage("No se ha podido validar la autorizacion por Captcha, intente nuevamente")
                                                    // }
                                                }}
                                                // onFinishFailed={onFinishFailed}
                                                autoComplete="off"
                                                rootClassName='pb-3'
                                            >
                                                <div>
                                                    <label className="form-label">{messages["login_username"]}</label>
                                                    <Form.Item
                                                        name="username"
                                                        rules={[{ required: true, message: messages["required_field"] }]}
                                                    >
                                                        <Input placeholder={messages["login_username_placeholder"]} className="form-control" />
                                                    </Form.Item>
                                                </div>

                                                <div className="form-password-toggle pt-3">
                                                    <div className="d-flex justify-content-between">
                                                        <label className="form-label">{messages["login_password"]}</label>
                                                        {/* <a href="auth-forgot-password-basic.html">
                                                            <small>{messages["login_forgot_password"]}</small>
                                                        </a> */}
                                                    </div>
                                                    <Form.Item
                                                        name="password"
                                                        rules={[{ required: true, message: messages["required_field"] }]}
                                                    >
                                                        <Input.Password rootClassName='form-control d-flex' placeholder={messages["login_password"]} />
                                                    </Form.Item>
                                                </div>

                                            </Form>

                                            <div className="pb-3">
                                                <Button className="btn btn-primary w-100" onClick={() => { form.submit() }}>{messages["login_singin"]} </Button>
                                            </div>
                                            <div className="text-center">
                                                <a className="d-flex align-items-center justify-content-center" onClick={() => { navigate("/recoveryPassword") }}>
                                                    {messages["login_recovery_password"]}
                                                    <i className="bx bx-chevron-right scaleX-n1-rtl bx-sm"></i>
                                                </a>
                                            </div>
                                        </Loading>
                                    </div>
                            }

                            {/* <form id="formAuthentication" className="mb-3" action="index.html" method="POST">
                                <div className="mb-3">
                                    <label for="email" className="form-label">Email or Username</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="email"
                                        name="email-username"
                                        placeholder="Enter your email or username"
                                        autofocus
                                    />
                                </div>
                                <div className="mb-3 form-password-toggle">
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label" for="password">Password</label>
                                        <a href="auth-forgot-password-basic.html">
                                            <small>Forgot Password?</small>
                                        </a>
                                    </div>
                                    <div className="input-group input-group-merge">
                                        <input
                                            type="password"
                                            id="password"
                                            className="form-control"
                                            name="password"
                                            placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                            aria-describedby="password"
                                        />
                                        <span className="input-group-text cursor-pointer"><i className="bx bx-hide"></i></span>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="remember-me" />
                                        <label className="form-check-label" for="remember-me"> Remember Me </label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <button className="btn btn-primary d-grid w-100" type="submit">Sign in</button>
                                </div>
                            </form>

                            <p className="text-center">
                                <span>New on our platform?</span>
                                <a href="auth-register-basic.html">
                                    <span>Create an account</span>
                                </a>
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        // </GoogleReCaptchaProvider>
    )
}

const LoginView = () => {
    return (
        // <GoogleReCaptchaProvider reCaptchaKey={captcha.REACT_APP_SITE_KEY}>
        <LoginViewInternal />
        // </GoogleReCaptchaProvider>
    )
}

export default LoginView;