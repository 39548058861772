import { Input, Table, Tag, Space, Collapse } from 'antd'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux'
import { usePaggination, useSecurity } from '../../../../../../config/hooks';
import { TableOptions } from "components";
import { P_PARAMETRIZABLE_TEXTS } from '../../../../../../config/helpers/permissionsHash';
import { parametrizableTextFromDelete, parametrizableTextsSearch, reset } from './redux/slice';
import { globalParametrizableTextEditOpen } from '../../modals/editParametrizableText/redux/slice';
import { EditParametrizableText } from '../../modals/editParametrizableText';
import { ConfirmDeleteModal } from '../../../../../../components/ConfirmDeleteModal';

export const ParametrizableText = () => {

    const { Panel } = Collapse;
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [elementToDelete, setElementToDelete] = useState(null);
    const dispatch = useDispatch();
    const { messages } = useIntl();
    const { data, loading, error } =
        useSelector((state) => state.parametrizableTextsListRedux);

    const R = useSecurity(P_PARAMETRIZABLE_TEXTS, ['R']);
    const D = useSecurity(P_PARAMETRIZABLE_TEXTS, ['D']);
    const U = useSecurity(P_PARAMETRIZABLE_TEXTS, ['U']);

    const paggination = usePaggination(parametrizableTextsSearch);

    useEffect(() => {
        paggination.refresh();
        return () => {
            dispatch(reset());
        };
    }, []);

    const limitContent = (content, limit = 100) => {
        if (content.length <= limit) {
            return content;
        }
        return `${content.slice(0, limit)}...`;
    };


    // funcion para abrir el modal
    const handleDeleteModal = (id) => {
        // console.log(id);
        setElementToDelete(id);
        setOpenDeleteModal(true);
    }

    // funcion para cerrar el modal 
    const closeDeleteModal = () => {
        setOpenDeleteModal(false);
        setElementToDelete(null);
    }

    // funcion para eliminar textoparameterizable por dispatch
    const deleteOptionText = () => {
        dispatch(parametrizableTextFromDelete({ id: elementToDelete }));
        setElementToDelete(null);
        setOpenDeleteModal(false);
        paggination.refresh();
    }

    // console.log('data?.items', data?.items);
    const columns = useMemo(
        () => [
            {
                width: 50,
                render: (values) => {
                    return (<TableOptions options={[
                        {
                            label: messages["edit"],
                            onClick: () => dispatch(
                                globalParametrizableTextEditOpen({
                                    id: values?.id,
                                })
                            ),
                            show: U
                        },
                        {
                            label: messages["delete"],
                            onClick: () => handleDeleteModal(values?.id),
                            show: D
                        }
                    ]} />)
                },

            },
            {
                title: 'uri',
                key: "uri",
                dataIndex: "uri",
            },
            {
                width: 200,
                title: 'roles',
                key: "roles",
                dataIndex: "roles",
                render: (roles) => (
                    <Space
                        direction="vertical"
                        size={[0, 8]}
                    >
                        {roles.map((role) => (
                            <Tag classNamep="p-2" color="processing" key={role.name}>{role.name}</Tag>
                        ))}
                    </Space>
                ),
            },
            {
                title: 'content',
                key: "content",
                dataIndex: "content",
                render: (content) => (

                    limitContent(content)

                ),
            },
            {
                title: 'description',
                key: "description",
                dataIndex: "description",
            },
        ]
    )

    return (

        <Fragment>
            <div className='row'>
                <div className='col-md-4 col-sm-12'>
                    {R && <Input
                        type="text"
                        className="form-control my-1 w-100"
                        placeholder={messages["search"]}
                        onChange={paggination.onSearchChange}
                        value={paggination.searchText}
                    />}
                </div>
                <div className="col-md-8 col-sm-12">
                    <div className='text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap'>
                        {R && <button
                            className="btn btn-primary m-1"
                            onClick={() => {
                                paggination.refresh();
                            }}
                        >
                            {messages["refresh"]}
                        </button>}
                        {R && <button
                            className="btn btn-primary m-1"
                            onClick={() => {
                                dispatch(globalParametrizableTextEditOpen({ id: null }));
                            }}
                        >
                            {messages["new"]}
                        </button>}
                    </div>
                </div>
            </div>
            <div className='mt-4 table-responsive'>
                {R && <Table
                    rowKey="id"
                    columns={columns}
                    loading={loading}
                    dataSource={data?.items || []}
                    pagination={{
                        total: data?.count || 0,
                        ...paggination.tablePaggination,
                    }}
                />}
            </div>
            <EditParametrizableText
                onClose={(refresh) => {
                    refresh && paggination.refresh();
                }}
            />
            <ConfirmDeleteModal
                show={openDeleteModal}
                onCancel={() => closeDeleteModal()}
                onOk={() => deleteOptionText()}
            />
        </Fragment>
    )
}
