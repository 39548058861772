import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { endpoints } from '../../../../config/constants';
import { ExceptionManager } from '../../../../config/helpers/Logging'
import { resetALL } from '../../../../config/redux/actions'
import { clientMutation } from "../../../../config/helpers/GraphQLApi";
import { getUserProfile } from "../../../../core/security/auth/redux/slice";
import { uploadFile } from "../../../../modules/common/files/redux/slice";

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    open: false,
    result: null,
    error: null
};

const route = 'components/ModalAffiliatePartner/redux/slice'

//Extend async Action
export const uploadContract = createAsyncThunk('modalAffiliatePartner/uploadContract', async (
    { id, file },
    { rejectWithValue, dispatch }
) => {

    try {
        let signedData = null;
        if(file){
            //todo
            signedData = await uploadFile({ file, entityName: "announcement_contract", entityId: id })
        }
        let data = await clientMutation(
            `mutation MUTATION($id: Int!, $file: InputFile!){
                result: uploadAnnouncementContract(id: $id, file: $file, sendEmail: true) 
            }`,
            {
                id,
                file: {
                    id: signedData.uuidKey,
                    uuidKey: signedData.uuidKey,
                    url: signedData.url,
                    name: signedData.name,
                    type: signedData.extension
                }
            },
            endpoints.GRAPHQL_GENERAL
        );

        dispatch(getUserProfile())
        return data?.result || []

    } catch (exc) {
        ExceptionManager(exc, route, 'uploadContract', dispatch);
        return rejectWithValue(exc)
    }
})

//Slice
const modalAffiliatePartnerRedux = createSlice({
    name: 'modalAffiliatePartnerRedux',
    initialState: INIT_STATE,
    reducers: {
        openModalAffiliatePartner(state){
            state.open = true;
        },
        close(state){
            state.close = false;
        },
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(uploadContract.pending, (state) => {
            state.loading = true
        })
        builder.addCase(uploadContract.fulfilled, (state, { payload }) => {
            state.loading = false
            state.result = payload
        })
        builder.addCase(uploadContract.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message
        })
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = modalAffiliatePartnerRedux

// Extract and export each action creator by name
export const { reset, close, openModalAffiliatePartner } = actions

//Simple async action

// Export the reducer, either as a default or named export
export default reducer