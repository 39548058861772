import { useRef } from "react";
import { useIntl } from "react-intl";

const TrashIcon = ({ onClick }) => (
  <>
    <div
      style={{
        backgroundColor: "red",
        width: 25,
        height: 25,
        borderRadius: 20,
      }}
    />
    {/* <div className="icon" onClick={onClick}>
    <i className="feather-sm" data-feather="trash" />
  </div> */}
  </>
);
export const InputFile = ({
  value,
  onChange,
  accept,
  multi = false,
  onDelete,
}) => {
  const { messages } = useIntl();
  const fileUploadRef = useRef();

  let src = "/assets/images/svg/avatars/blank.png";
  if (value?.url) {
    src = value?.url;
  } else if (value?.file) {
    src = URL.createObjectURL(value?.file);
  }

  return (
    <div className="d-block">
      <div className="col-12">
        <button
          type="button"
          className="mt-2 ms-auto btn btn-sm btn-rounded btn-success"
          onClick={() => {
            fileUploadRef.current.click();
          }}
        >
          {messages["companiesDetailPayments_addFiles"]}
        </button>
        <input
          ref={fileUploadRef}
          style={{ display: "none" }}
          type="file"
          accept={accept}
          onChange={(e) => {
            if(e?.target?.files?.length > 0){
              var reader = new FileReader();
              reader.onload = function () {
                onChange({ file: e.target.files[0] });
              };
              reader.readAsDataURL(e.target.files[0]);
            }
          }}
        />
        <div className="row mt-1">
          {multi && !!value?.length &&
            value.map((f, i) => (
              <div key={i} className="row mb-1">
                <div className="col-md-10">
                    <small  className="text-muted">
                      {i + 1 + ". "}
                      {f?.file?.name}
                    </small>
                </div>
                <div className="col-2">
                    {onDelete && <button
                      type="button"
                      className="mt-2 ms-auto btn btn-sm btn-rounded  btn-danger"
                      onClick={() => onDelete(i)}
                    >
                      {messages["delete"]}
                    </button>}
                </div> 
              </div>
            ))}
          {!multi && !!value && (
            <small className="text-muted">
              {value?.file?.name}
              <i className="feather-sm" data-feather="trash" />
            </small>
          )}
        </div>
      </div>
    </div>
  );
};
