import { ConfigProvider } from 'antd';
import { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter } from 'react-router-dom'

import store from './config/redux/store'
import { Provider } from 'react-redux'

import MainRouter from './core/main'

import './assets/vendors/fonts/boxicons.css'
import './assets/vendors/fonts/flag-icons.css'


import 'assets/sass/core.scss';
import 'assets/sass/theme-default.scss';
import 'assets/css/demo.css';

const App = () => {
  return (
    <Fragment>
      <ConfigProvider theme={{token: {colorPrimary: '#696cff'}}}>
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path='*' element={<MainRouter />} />
            </Routes>
          </BrowserRouter>
        </Provider>
      </ConfigProvider>
    </Fragment>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
