import { useEffect, useMemo, useState } from "react"
import { Radio } from 'antd';

const YesOrNoInput = (props) => {

    const [defaultValue, setDefaultValue] = useState(true)

    const changeOption = (e) => {
        setDefaultValue(e.target.value);
    }

    return (
        <div className="row">
            <Radio.Group name="radiogroup" value={defaultValue} onChange={changeOption}>
                <Radio value={true}  >Yes</Radio>
                <Radio value={false} >No</Radio>
            </Radio.Group>
        </div>
    )
}

export default YesOrNoInput
